/**
 * Site settings
 *
 * This allow to setup default behavior.
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Fonts
   ========================================================================== */

@include font-face('DINProRegular', '/assets/fonts/DINPro-Regular');
@include font-face('DINProMedium', '/assets/fonts/DINPro-Medium');
@include font-face('DINProBold', '/assets/fonts/DINPro-Bold');
