/**
 * Input
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Input
   ========================================================================== */

/**
 * RC Input
 *
 *
 * Example HTML:
 *
 * <input type="text" placeholder="Hello world" class="rc-input">
 *
 */

/**
 * 1. Chrome/Opera/Safari
 * 2. Firefox 19+
 * 3. IE 10+
 * 4. Firefox 18-
 */

.rc-input-container {
  position: relative;

  &--search {
    margin: 0 auto;
    position: relative;
    width: 100%;
  }

  .rc-icon--search {
    bottom: 17px;

    height: 16px;
    position: absolute;
    width: 16px;
  }
}

.rc-input {
  -webkit-appearance: none;
  background: none;

  border: 0;
  border-radius: 0;
  color: $grey;

  font-family: 'DINProMedium', Roboto, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin: 0;
  min-width: 30px;
  padding: 0;
  width: 100%;

  &::placeholder {
    color: $grey-light;
  }

  &:disabled {
    color: $grey-light;
    cursor: not-allowed;
  }

  &--underlined {
    border-bottom: 2px solid #d7d7d7;
    height: 50px;
  }

  &-button {
    cursor: pointer;

    margin-left: 20px;
    position: relative;
    top: 25%;
  }

  &--error {
    border-color: $error;
    color: $error;
  }
  &--error::placeholder {
    color: $error;
  }
  &--warning {
    border-color: $warning;
    color: $warning;
  }
  &--warning::placeholder {
    color: $warning;
  }
  &--warning:disabled {
    color: $warning;
  }
  &--error-message,
  &--warning-message {
    font-size: 11.2px;
    height: 0px;
    margin: 3px 0 -3px 0px;
  }
  &--error-message {
    color: $error;
  }
  &--warning-message {
    color: $warning;
  }

  &--search {
    padding-left: 25px;
  }
}

.rc-input__wrapper {
  align-items: center;
  display: flex;
}

.rc-textarea {
  -webkit-appearance: none;
  border: 1px solid $grey-lighter;
  height: 150px;
  padding: 10px;
  resize: none;
  width: 100%;
}

/*
 Responsive design
 ========================================================================== */

/**
 * Devices between (960px and up)
 */
@media only screen and (min-width: 960px) {
  .rc-input-container .rc-icon--search {
    background-position: -66px -216px;

    &:hover::after {
      background-position: initial;
      opacity: 0;
    }
  }
}
