/**
 * Base
 *
 * The role of base is to have all default settings configuration.
 * Must contains only default config, you should never override class
 * on this file.
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

html,
body {
  height: 100%;
  width: 100%;
}

body {
  color: $grey;
  font-family: $din-regular;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  -webkit-print-color-adjust: exact !important;
}

a {
  text-decoration: none;
}

.center {
  text-align: center;
}
.left {
  float: left;
}
.right {
  float: right;
}
.clear {
  clear: both;
}
.no-scroll {
  overflow: hidden;
}
.divider-table {
  background-color: $app-background;
  height: 1px;
  width: 100%;
}

.divider {
  background-color: $app-background;
  height: 1px;
  margin: 20px 0;
  width: 100%;
}

.rc-reset-list {
  list-style: none;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  padding-inline-start: 0;
}

/* Mars Footer
   ========================================================================== */

/**
 * Override mars footer
 */

.mars-footer-container {
  position: relative !important;
  top: 20px !important;
}

.mars-footer-legal {
  margin-bottom: 0 !important;
}

/* Print View
   ========================================================================== */

@media print {
  @page {
    margin: 0mm;
    size: auto;
  }

  .optanon-alert-box-wrapper,
  #cookie-consent {
    display: none;
  }
  .mars-footer-container {
    display: none;
  }
}
