/**
 * Mixins
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

@import 'site-variables';
@import 'functions';

/* Font Face
   ========================================================================== */

/**
 * Helper to load font face
 */

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(map.has-key($extmods, $ext), $ext + map.get($extmods, $ext), $ext);
    $format: if(map.has-key($formats, $ext), map.get($formats, $ext), $ext);
    $src: list.append($src, url(quote($path + '.' + $extmod)) format(string.quote($format)), comma);
  }

  @font-face {
    font-family: string.quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

/* Media query
   ========================================================================== */

/**
 * Helper for media query
 */
