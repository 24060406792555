/* stylelint-disable */
@import 'site-variables';

.rc-icon.rc-attention--xs.rc-iconography::after,
.rc-icon.rc-attention--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-cat-tree--xs.rc-iconography::after,
.rc-icon.rc-cat-tree--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-children--xs.rc-iconography::after,
.rc-icon.rc-children--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-deafness--xs.rc-iconography::after,
.rc-icon.rc-deafness--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-enjoys-training--xs.rc-iconography::after,
.rc-icon.rc-enjoys-training--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-exercise--xs.rc-iconography::after,
.rc-icon.rc-exercise--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-experience--xs.rc-iconography::after,
.rc-icon.rc-experience--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-family--xs.rc-iconography::after,
.rc-icon.rc-family--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-first-time--xs.rc-iconography::after,
.rc-icon.rc-first-time--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-friendly--xs.rc-iconography::after,
.rc-icon.rc-friendly--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-grooming--xs.rc-iconography::after,
.rc-icon.rc-grooming--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-guard-dog--xs.rc-iconography::after,
.rc-icon.rc-guard-dog--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-heat--xs.rc-iconography::after,
.rc-icon.rc-heat--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-human-interaction--xs.rc-iconography::after,
.rc-icon.rc-human-interaction--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-hunt--xs.rc-iconography::after,
.rc-icon.rc-hunt--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-incompatible--xs.rc-iconography::after,
.rc-icon.rc-incompatible--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-indoor-outdoor--xs.rc-iconography::after,
.rc-icon.rc-indoor-outdoor--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-indoors--xs.rc-iconography::after,
.rc-icon.rc-indoors--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-low-maintenance--xs.rc-iconography::after,
.rc-icon.rc-low-maintenance--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-need-space--xs.rc-iconography::after,
.rc-icon.rc-need-space--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-no-alone--xs.rc-iconography::after,
.rc-icon.rc-no-alone--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-no-garden--xs.rc-iconography::after,
.rc-icon.rc-no-garden--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-outdoor--xs.rc-iconography::after,
.rc-icon.rc-outdoor--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-pairs--xs.rc-iconography::after,
.rc-icon.rc-pairs--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-patient--xs.rc-iconography::after,
.rc-icon.rc-patient--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-quiet-home--xs.rc-iconography::after,
.rc-icon.rc-quiet-home--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-sm-home--xs.rc-iconography::after,
.rc-icon.rc-sm-home--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-sporting--xs.rc-iconography::after,
.rc-icon.rc-sporting--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-startled--xs.rc-iconography::after,
.rc-icon.rc-startled--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-training--xs.rc-iconography::after,
.rc-icon.rc-training--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-versatile--xs.rc-iconography::after,
.rc-icon.rc-versatile--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-watchdog--xs.rc-iconography::after,
.rc-icon.rc-watchdog--xs[class*='--xs'].rc-iconography::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--breed--iconography--xs.svg);
}
.btn--icon-label.rc-icon:not(.btn--inverse)[aria-selected='true'],
.btn--icon-label.rc-icon:not(.rc-btn--inverse)[aria-selected='true'],
.rc-btn--icon-label.rc-icon:not(.btn--inverse)[aria-selected='true'],
.rc-btn--icon-label.rc-icon:not(.rc-btn--inverse)[aria-selected='true'],
.rc-btn--icon-tab.rc-icon:not(.btn--inverse)[aria-selected='true'],
.rc-btn--icon-tab.rc-icon:not(.rc-btn--inverse)[aria-selected='true'] {
  background-color: #fff;
}
.rc-icon.interactive:hover::after,
.rc-icon::after {
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  height: 32px;
  overflow: hidden;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  width: 32px;
}
.rc-icon {
  vertical-align: middle;
}
@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .rc-icon {
    margin-left: -1px;
  }
}
.input.rc-icon,
.list__item.rc-icon,
.rc-icon::after,
.rc-input.rc-icon,
.rc-list__item.rc-icon,
.rc-wysiwyg ol > li.rc-icon,
.rc-wysiwyg ul > li.rc-icon {
  position: relative;
}
.rc-icon.interactive {
  -webkit-transition: background-image 0.2s;
  transition: background-image 0.2s;
}
.rc-icon--rotate:not(.rc-btn--icon-label)::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.rc-icon[class*='--xs']::after {
  width: 24px;
}
.rc-icon.close-error--xs::after,
.rc-icon.close-success--xs::after,
.rc-icon.close-warning--xs::after,
.rc-icon.error--xs::after,
.rc-icon.facebook--xs::after,
.rc-icon.google-plus--xs::after,
.rc-icon.instagram--xs::after,
.rc-icon.pinterest--xs::after,
.rc-icon.rc-close-error--xs::after,
.rc-icon.rc-close-success--xs::after,
.rc-icon.rc-close-warning--xs::after,
.rc-icon.rc-error--xs::after,
.rc-icon.rc-facebook--xs::after,
.rc-icon.rc-google-plus--xs::after,
.rc-icon.rc-instagram--xs::after,
.rc-icon.rc-pinterest--xs::after,
.rc-icon.rc-success--xs::after,
.rc-icon.rc-twitter--xs::after,
.rc-icon.rc-warning--xs::after,
.rc-icon.rc-youtube--xs::after,
.rc-icon.success--xs::after,
.rc-icon.twitter--xs::after,
.rc-icon.warning--xs::after,
.rc-icon.youtube--xs::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--single--xs.svg);
}
.rc-icon.close-error--xs::after,
.rc-icon.rc-close-error--xs::after {
  background-position: 33.33% 0;
}
.rc-icon.close-success--xs::after,
.rc-icon.rc-close-success--xs::after,
.rc-icon.rc-sustainable::after,
.rc-icon.rc-watchdog--sm::after,
.rc-icon.sustainable::after,
.rc-icon.watchdog--sm::after {
  background-position: 100% 0;
}
.rc-icon.close-warning--xs::after,
.rc-icon.rc-close-warning--xs::after {
  background-position: 66.67% 0;
}
.rc-icon.edit::after,
.rc-icon.error--xs::after,
.rc-icon.exercise::after,
.rc-icon.rc-edit::after,
.rc-icon.rc-error--xs::after,
.rc-icon.rc-exercise::after {
  background-position: 0 50%;
}
.rc-icon.facebook--xs::after,
.rc-icon.rc-facebook--xs::after {
  background-position: 33.33% 50%;
}
.rc-icon.google-plus--xs::after,
.rc-icon.rc-google-plus--xs::after {
  background-position: 66.67% 50%;
}
.rc-icon.incompatible--sm::after,
.rc-icon.instagram--xs::after,
.rc-icon.loading::after,
.rc-icon.rc-incompatible--sm::after,
.rc-icon.rc-instagram--xs::after,
.rc-icon.rc-loading::after {
  background-position: 0 0;
}
.rc-icon.pinterest--xs::after,
.rc-icon.rc-pinterest--xs::after {
  background-position: 100% 50%;
}
.rc-icon.rc-startled::after,
.rc-icon.rc-success--xs::after,
.rc-icon.rc-vet--sm::after,
.rc-icon.startled::after,
.rc-icon.success--xs::after,
.rc-icon.vet--sm::after {
  background-position: 0 100%;
}
.rc-icon.rc-twitter--xs::after,
.rc-icon.twitter--xs::after {
  background-position: 33.33% 100%;
}
.rc-icon.rc-warning--xs::after,
.rc-icon.warning--xs::after {
  background-position: 66.67% 100%;
}
.rc-icon.rc-youtube--xs::after,
.rc-icon.youtube--xs::after {
  background-position: 100% 100%;
}
.rc-icon.attention--xs::after,
.rc-icon.rc-attention--xs::after {
  background-position: 16.67% 0;
  width: 24px;
}
.rc-icon.cat-tree--xs::after,
.rc-icon.rc-cat-tree--xs::after {
  background-position: 83.33% 25%;
  width: 24px;
}
.rc-icon.children--xs::after,
.rc-icon.rc-children--xs::after {
  background-position: 33.33% 0;
  width: 24px;
}
.rc-icon.deafness--xs::after,
.rc-icon.rc-deafness--xs::after {
  background-position: 0 25%;
  width: 24px;
}
.rc-icon.enjoys-training--xs::after,
.rc-icon.rc-enjoys-training--xs::after {
  background-position: 16.67% 25%;
  width: 24px;
}
.rc-icon.exercise--xs::after,
.rc-icon.rc-exercise--xs::after {
  background-position: 33.33% 25%;
  width: 24px;
}
.rc-icon.experience--xs::after,
.rc-icon.rc-experience--xs::after {
  background-position: 50% 0;
  width: 24px;
}
.rc-icon.family--xs::after,
.rc-icon.rc-family--xs::after {
  background-position: 50% 25%;
  width: 24px;
}
.rc-icon.first-time--xs::after,
.rc-icon.rc-first-time--xs::after {
  background-position: 0 50%;
  width: 24px;
}
.rc-icon.friendly--xs::after,
.rc-icon.rc-friendly--xs::after {
  background-position: 16.67% 50%;
  width: 24px;
}
.rc-icon.grooming--xs::after,
.rc-icon.rc-grooming--xs::after {
  background-position: 33.33% 50%;
  width: 24px;
}
.rc-icon.guard-dog--xs::after,
.rc-icon.rc-guard-dog--xs::after {
  background-position: 50% 50%;
  width: 24px;
}
.rc-icon.heat--xs::after,
.rc-icon.rc-heat--xs::after {
  background-position: 66.67% 0;
  width: 24px;
}
.rc-icon.human-interaction--xs::after,
.rc-icon.rc-human-interaction--xs::after {
  background-position: 66.67% 25%;
  width: 24px;
}
.rc-icon.hunt--xs::after,
.rc-icon.rc-hunt--xs::after {
  background-position: 66.67% 50%;
  width: 24px;
}
.rc-icon.incompatible--xs::after,
.rc-icon.rc-incompatible--xs::after {
  background-position: 83.33% 0;
  width: 24px;
}
.rc-icon.indoor-outdoor--xs::after,
.rc-icon.lock--xs::after,
.rc-icon.rc-indoor-outdoor--xs::after,
.rc-icon.rc-lock--xs::after {
  background-position: 0 0;
  width: 24px;
}
.rc-icon.indoors--xs::after,
.rc-icon.rc-indoors--xs::after {
  background-position: 83.33% 50%;
  width: 24px;
}
.rc-icon.low-maintenance--xs::after,
.rc-icon.rc-low-maintenance--xs::after {
  background-position: 0 75%;
  width: 24px;
}
.rc-icon.need-space--xs::after,
.rc-icon.rc-need-space--xs::after {
  background-position: 16.67% 75%;
  width: 24px;
}
.rc-icon.no-alone--xs::after,
.rc-icon.rc-no-alone--xs::after {
  background-position: 33.33% 75%;
  width: 24px;
}
.rc-icon.no-garden--xs::after,
.rc-icon.rc-no-garden--xs::after {
  background-position: 50% 75%;
  width: 24px;
}
.rc-icon.outdoor--xs::after,
.rc-icon.rc-outdoor--xs::after {
  background-position: 66.67% 75%;
  width: 24px;
}
.rc-icon.pairs--xs::after,
.rc-icon.rc-pairs--xs::after {
  background-position: 83.33% 75%;
  width: 24px;
}
.rc-icon.patient--xs::after,
.rc-icon.rc-patient--xs::after,
.rc-icon.rc-volume-on--xs::after,
.rc-icon.volume-on--xs::after {
  background-position: 100% 0;
  width: 24px;
}
.rc-icon.quiet-home--xs::after,
.rc-icon.rc-quiet-home--xs::after {
  background-position: 100% 25%;
  width: 24px;
}
.rc-icon.rc-sm-home--xs::after,
.rc-icon.sm-home--xs::after {
  background-position: 100% 50%;
  width: 24px;
}
.rc-icon.rc-sporting--xs::after,
.rc-icon.sporting--xs::after {
  background-position: 100% 75%;
  width: 24px;
}
.rc-icon.rc-startled--xs::after,
.rc-icon.rc-tablet--xs::after,
.rc-icon.startled--xs::after,
.rc-icon.tablet--xs::after {
  background-position: 0 100%;
  width: 24px;
}
.rc-icon.rc-training--xs::after,
.rc-icon.training--xs::after {
  background-position: 16.67% 100%;
  width: 24px;
}
.rc-icon.rc-versatile--xs::after,
.rc-icon.versatile--xs::after {
  background-position: 33.33% 100%;
  width: 24px;
}
.rc-icon.rc-watchdog--xs::after,
.rc-icon.watchdog--xs::after {
  background-position: 50% 100%;
  width: 24px;
}
.rc-icon.actions--xs::after {
  background-position: 9.09% 0;
  width: 24px;
}
.rc-icon.address--xs::after,
.rc-icon.rc-address--xs::after {
  background-position: 45.45% 71.43%;
  width: 24px;
}
.rc-icon.advice--xs::after {
  background-position: 18.18% 0;
  width: 24px;
}
.rc-icon.arrow--xs::after,
.rc-icon.rc-arrow--xs::after {
  background-position: 0 14.29%;
  width: 24px;
}
.rc-icon.attachment--xs::after,
.rc-icon.rc-attachment--xs::after {
  background-position: 9.09% 14.29%;
  width: 24px;
}
.rc-icon.back--xs::after,
.rc-icon.rc-back--xs::after {
  background-position: 18.18% 14.29%;
  width: 24px;
}
.rc-icon.bag--xs::after,
.rc-icon.rc-bag--xs::after {
  background-position: 27.27% 0;
  width: 24px;
}
.rc-icon.basket--xs::after,
.rc-icon.rc-basket--xs::after {
  background-position: 27.27% 14.29%;
  width: 24px;
}
.rc-icon.bookmark--xs::after,
.rc-icon.rc-bookmark--xs::after {
  background-position: 0 28.57%;
  width: 24px;
}
.rc-icon.calendar--xs::after,
.rc-icon.rc-calendar--xs::after {
  background-position: 9.09% 28.57%;
  width: 24px;
}
.rc-icon.cart--xs::after,
.rc-icon.rc-cart--xs::after {
  background-position: 18.18% 28.57%;
  width: 24px;
}
.rc-icon.clock--xs::after,
.rc-icon.rc-clock--xs::after {
  background-position: 27.27% 28.57%;
  width: 24px;
}
.rc-icon.close--xs::after {
  background-position: 36.36% 0;
  width: 24px;
}
.rc-icon.collapse--xs::after,
.rc-icon.rc-collapse--xs::after {
  background-position: 36.36% 14.29%;
  width: 24px;
}
.rc-icon.comment--xs::after,
.rc-icon.rc-comment--xs::after {
  background-position: 36.36% 28.57%;
  width: 24px;
}
.rc-icon.computer--xs::after,
.rc-icon.rc-computer--xs::after {
  background-position: 45.45% 0;
  width: 24px;
}
.rc-icon.contact--xs::after,
.rc-icon.rc-contact--xs::after {
  background-position: 45.45% 14.29%;
  width: 24px;
}
.rc-icon.documents--xs::after {
  background-position: 45.45% 28.57%;
  width: 24px;
}
.rc-icon.down--xs::after,
.rc-icon.rc-down--xs::after {
  background-position: 0 42.86%;
  width: 24px;
}
.rc-icon.download--xs::after,
.rc-icon.rc-download--xs::after {
  background-position: 9.09% 42.86%;
  width: 24px;
}
.rc-icon.edit--xs::after,
.rc-icon.rc-edit--xs::after {
  background-position: 18.18% 42.86%;
  width: 24px;
}
.rc-icon.email--xs::after {
  background-position: 27.27% 42.86%;
  width: 24px;
}
.rc-icon.expand--xs::after,
.rc-icon.rc-expand--xs::after {
  background-position: 36.36% 42.86%;
  width: 24px;
}
.rc-icon.external--xs::after,
.rc-icon.rc-external--xs::after {
  background-position: 45.45% 42.86%;
  width: 24px;
}
.rc-icon.fast-forward--xs::after,
.rc-icon.rc-fast-forward--xs::after {
  background-position: 54.55% 0;
  width: 24px;
}
.rc-icon.female--xs::after,
.rc-icon.rc-female--xs::after {
  background-position: 54.55% 14.29%;
  width: 24px;
}
.rc-icon.filter--xs::after,
.rc-icon.rc-filter--xs::after {
  background-position: 54.55% 28.57%;
  width: 24px;
}
.rc-icon.food--xs::after,
.rc-icon.rc-food--xs::after {
  background-position: 54.55% 42.86%;
  width: 24px;
}
.rc-icon.food2--xs::after,
.rc-icon.rc-food2--xs::after {
  background-position: 0 57.14%;
  width: 24px;
}
.rc-icon.forward--xs::after,
.rc-icon.rc-forward--xs::after {
  background-position: 9.09% 57.14%;
  width: 24px;
}
.rc-icon.grid-view--xs::after,
.rc-icon.rc-grid-view--xs::after {
  background-position: 18.18% 57.14%;
  width: 24px;
}
.rc-icon.groomer--xs::after,
.rc-icon.rc-groomer--xs::after {
  background-position: 27.27% 57.14%;
  width: 24px;
}
.rc-icon.health--xs::after,
.rc-icon.rc-health--xs::after {
  background-position: 36.36% 57.14%;
  width: 24px;
}
.rc-icon.help--xs::after,
.rc-icon.rc-help--xs::after {
  background-position: 45.45% 57.14%;
  width: 24px;
}
.rc-icon.hide--xs::after,
.rc-icon.rc-hide--xs::after {
  background-position: 54.55% 57.14%;
  width: 24px;
}
.rc-icon.home--xs::after {
  background-position: 63.64% 0;
  width: 24px;
}
.rc-icon.image--xs::after,
.rc-icon.rc-image--xs::after {
  background-position: 63.64% 14.29%;
  width: 24px;
}
.rc-icon.info--xs::after,
.rc-icon.rc-info--xs::after {
  background-position: 63.64% 28.57%;
  width: 24px;
}
.rc-icon.knowledge--xs::after,
.rc-icon.rc-knowledge--xs::after {
  background-position: 63.64% 42.86%;
  width: 24px;
}
.rc-icon.left--xs::after {
  background-position: 63.64% 57.14%;
  width: 24px;
}
.rc-icon.like--xs::after,
.rc-icon.rc-like--xs::after {
  background-position: 0 71.43%;
  width: 24px;
}
.rc-icon.list-view--xs::after,
.rc-icon.rc-list-view--xs::after {
  background-position: 9.09% 71.43%;
  width: 24px;
}
.rc-icon.loading--xs::after,
.rc-icon.rc-loading--xs::after {
  background-position: 18.18% 71.43%;
  width: 24px;
}
.rc-icon.location--xs::after,
.rc-icon.rc-location--xs::after {
  background-position: 27.27% 71.43%;
  width: 24px;
}
.rc-icon.location2--xs::after,
.rc-icon.rc-location2--xs::after {
  background-position: 36.36% 71.43%;
  width: 24px;
}
.rc-icon.male--xs::after,
.rc-icon.rc-male--xs::after {
  background-position: 54.55% 71.43%;
  width: 24px;
}
.rc-icon.menu--xs::after {
  background-position: 63.64% 71.43%;
  width: 24px;
}
.rc-icon.minus--xs::after,
.rc-icon.rc-minus--xs::after {
  background-position: 72.73% 0;
  width: 24px;
}
.rc-icon.mobile--xs::after {
  background-position: 72.73% 14.29%;
  width: 24px;
}
.rc-icon.mouse--xs::after,
.rc-icon.rc-mouse--xs::after {
  background-position: 72.73% 28.57%;
  width: 24px;
}
.rc-icon.new-window--xs::after,
.rc-icon.rc-new-window--xs::after {
  background-position: 72.73% 42.86%;
  width: 24px;
}
.rc-icon.news--xs::after,
.rc-icon.rc-news--xs::after {
  background-position: 72.73% 57.14%;
  width: 24px;
}
.rc-icon.nutritional--xs::after,
.rc-icon.rc-nutritional--xs::after {
  background-position: 72.73% 71.43%;
  width: 24px;
}
.rc-icon.pause--xs::after,
.rc-icon.rc-pause--xs::after {
  background-position: 81.82% 0;
  width: 24px;
}
.rc-icon.pdf--xs::after,
.rc-icon.rc-pdf--xs::after {
  background-position: 81.82% 14.29%;
  width: 24px;
}
.rc-icon.pharmacy--xs::after,
.rc-icon.rc-pharmacy--xs::after {
  background-position: 81.82% 28.57%;
  width: 24px;
}
.rc-icon.pin--xs::after {
  background-position: 81.82% 42.86%;
  width: 24px;
}
.rc-icon.play--xs::after,
.rc-icon.rc-play--xs::after {
  background-position: 81.82% 57.14%;
  width: 24px;
}
.rc-icon.plus--xs::after,
.rc-icon.rc-plus--xs::after {
  background-position: 81.82% 71.43%;
  width: 24px;
}
.rc-icon.print--xs::after,
.rc-icon.rc-print--xs::after {
  background-position: 0 85.71%;
  width: 24px;
}
.rc-icon.quality--xs::after,
.rc-icon.rc-quality--xs::after {
  background-position: 9.09% 85.71%;
  width: 24px;
}
.rc-icon.rate--xs::after,
.rc-icon.rc-rate--xs::after {
  background-position: 18.18% 85.71%;
  width: 24px;
}
.rc-icon.rate-fill--xs::after,
.rc-icon.rc-rate-fill--xs::after {
  background-position: 27.27% 85.71%;
  width: 24px;
}
.rc-icon.rc-refresh--xs::after,
.rc-icon.refresh--xs::after {
  background-position: 36.36% 85.71%;
  width: 24px;
}
.rc-icon.rc-rewind--xs::after,
.rc-icon.rewind--xs::after {
  background-position: 45.45% 85.71%;
  width: 24px;
}
.rc-icon.rc-right--xs::after,
.rc-icon.right--xs::after {
  background-position: 54.55% 85.71%;
  width: 24px;
}
.rc-icon.rc-rss--xs::after,
.rc-icon.rss--xs::after {
  background-position: 63.64% 85.71%;
  width: 24px;
}
.rc-icon.search--xs::after {
  background-position: 72.73% 85.71%;
  width: 24px;
}
.rc-icon.rc-settings--xs::after,
.rc-icon.settings--xs::after {
  background-position: 81.82% 85.71%;
  width: 24px;
}
.rc-icon.rc-share--xs::after,
.rc-icon.share--xs::after {
  background-position: 90.91% 0;
  width: 24px;
}
.rc-icon.rc-shelter--xs::after,
.rc-icon.shelter--xs::after {
  background-position: 90.91% 14.29%;
  width: 24px;
}
.rc-icon.rc-shop--xs::after,
.rc-icon.shop--xs::after {
  background-position: 90.91% 28.57%;
  width: 24px;
}
.rc-icon.rc-show--xs::after,
.rc-icon.show--xs::after {
  background-position: 90.91% 42.86%;
  width: 24px;
}
.rc-icon.rc-skip--xs::after,
.rc-icon.skip--xs::after {
  background-position: 90.91% 57.14%;
  width: 24px;
}
.rc-icon.rc-stop--xs::after,
.rc-icon.stop--xs::after {
  background-position: 90.91% 71.43%;
  width: 24px;
}
.rc-icon.rc-sustainable--xs::after,
.rc-icon.sustainable--xs::after {
  background-position: 90.91% 85.71%;
  width: 24px;
}
.rc-icon.rc-text-decrease--xs::after,
.rc-icon.text-decrease--xs::after {
  background-position: 9.09% 100%;
  width: 24px;
}
.rc-icon.rc-text-increase--xs::after,
.rc-icon.text-increase--xs::after {
  background-position: 18.18% 100%;
  width: 24px;
}
.rc-icon.rc-tick--xs::after,
.rc-icon.tick--xs::after {
  background-position: 27.27% 100%;
  width: 24px;
}
.rc-icon.rc-unlock--xs::after,
.rc-icon.unlock--xs::after {
  background-position: 36.36% 100%;
  width: 24px;
}
.rc-icon.up--xs::after {
  background-position: 45.45% 100%;
  width: 24px;
}
.rc-icon.user--xs::after {
  background-position: 54.55% 100%;
  width: 24px;
}
.rc-icon.rc-user-add--xs::after,
.rc-icon.user-add--xs::after {
  background-position: 63.64% 100%;
  width: 24px;
}
.rc-icon.rc-vet--xs::after,
.rc-icon.vet--xs::after {
  background-position: 72.73% 100%;
  width: 24px;
}
.rc-icon.rc-video--xs::after,
.rc-icon.video--xs::after {
  background-position: 81.82% 100%;
  width: 24px;
}
.rc-icon.rc-volume-off--xs::after,
.rc-icon.volume-off--xs::after {
  background-position: 90.91% 100%;
  width: 24px;
}
.rc-icon.rc-wechat--xs::after,
.rc-icon.wechat--xs::after {
  background-position: 100% 14.29%;
  width: 24px;
}
.rc-icon.rc-weight--xs::after,
.rc-icon.weight--xs::after {
  background-position: 100% 28.57%;
  width: 24px;
}
.rc-icon.attention::after,
.rc-icon.rc-attention::after {
  background-position: 11.11% 0;
}
.rc-icon.attention--sm::after,
.rc-icon.rc-attention--sm::after {
  background-position: 66.67% 62.5%;
}
.rc-icon.ball::after,
.rc-icon.rc-ball::after {
  background-position: 0 12.5%;
}
.rc-icon.ball--sm::after,
.rc-icon.rc-ball--sm::after {
  background-position: 11.11% 12.5%;
}
.rc-icon.behavior::after,
.rc-icon.rc-behavior::after {
  background-position: 22.22% 0;
}
.rc-icon.behavior--sm::after,
.rc-icon.rc-behavior--sm::after {
  background-position: 22.22% 12.5%;
}
.rc-icon.bug::after,
.rc-icon.rc-bug::after {
  background-position: 0 25%;
}
.rc-icon.bug--sm::after,
.rc-icon.rc-bug--sm::after {
  background-position: 11.11% 25%;
}
.rc-icon.cat-tree::after,
.rc-icon.rc-cat-tree::after {
  background-position: 22.22% 25%;
}
.rc-icon.cat-tree--sm::after,
.rc-icon.rc-cat-tree--sm::after {
  background-position: 33.33% 0;
}
.rc-icon.children::after,
.rc-icon.rc-children::after {
  background-position: 33.33% 12.5%;
}
.rc-icon.children--sm::after,
.rc-icon.rc-children--sm::after {
  background-position: 33.33% 25%;
}
.rc-icon.collar::after,
.rc-icon.rc-collar::after {
  background-position: 0 37.5%;
}
.rc-icon.collar--sm::after,
.rc-icon.rc-collar--sm::after {
  background-position: 11.11% 37.5%;
}
.rc-icon.deafness::after,
.rc-icon.rc-deafness::after {
  background-position: 22.22% 37.5%;
}
.rc-icon.deafness--sm::after,
.rc-icon.rc-deafness--sm::after {
  background-position: 33.33% 37.5%;
}
.rc-icon.digestion::after,
.rc-icon.rc-digestion::after {
  background-position: 44.44% 0;
}
.rc-icon.digestion--sm::after,
.rc-icon.rc-digestion--sm::after {
  background-position: 44.44% 12.5%;
}
.rc-icon.enjoys-training::after,
.rc-icon.rc-enjoys-training::after {
  background-position: 44.44% 25%;
}
.rc-icon.enjoys-training--sm::after,
.rc-icon.rc-enjoys-training--sm::after {
  background-position: 44.44% 37.5%;
}
.rc-icon.exercise--sm::after,
.rc-icon.rc-exercise--sm::after {
  background-position: 11.11% 50%;
}
.rc-icon.experience::after,
.rc-icon.rc-experience::after {
  background-position: 22.22% 50%;
}
.rc-icon.experience--sm::after,
.rc-icon.rc-experience--sm::after {
  background-position: 33.33% 50%;
}
.rc-icon.family::after,
.rc-icon.rc-family::after {
  background-position: 44.44% 50%;
}
.rc-icon.family--sm::after,
.rc-icon.rc-family--sm::after {
  background-position: 55.56% 0;
}
.rc-icon.first-time::after,
.rc-icon.rc-first-time::after {
  background-position: 55.56% 12.5%;
}
.rc-icon.first-time--sm::after,
.rc-icon.rc-first-time--sm::after {
  background-position: 55.56% 25%;
}
.rc-icon.friendly::after,
.rc-icon.rc-friendly::after {
  background-position: 55.56% 37.5%;
}
.rc-icon.friendly--sm::after,
.rc-icon.rc-friendly--sm::after {
  background-position: 55.56% 50%;
}
.rc-icon.grooming::after,
.rc-icon.rc-grooming::after {
  background-position: 0 62.5%;
}
.rc-icon.grooming--sm::after,
.rc-icon.rc-grooming--sm::after {
  background-position: 11.11% 62.5%;
}
.rc-icon.guard-dog::after,
.rc-icon.rc-guard-dog::after {
  background-position: 22.22% 62.5%;
}
.rc-icon.guard-dog--sm::after,
.rc-icon.rc-guard-dog--sm::after {
  background-position: 33.33% 62.5%;
}
.rc-icon.heat::after,
.rc-icon.rc-heat::after {
  background-position: 44.44% 62.5%;
}
.rc-icon.heat--sm::after,
.rc-icon.rc-heat--sm::after {
  background-position: 55.56% 62.5%;
}
.rc-icon.human-interaction::after,
.rc-icon.rc-human-interaction::after {
  background-position: 66.67% 0;
}
.rc-icon.human-interaction--sm::after,
.rc-icon.rc-human-interaction--sm::after {
  background-position: 66.67% 12.5%;
}
.rc-icon.hunt::after,
.rc-icon.rc-hunt::after {
  background-position: 66.67% 25%;
}
.rc-icon.hunt--sm::after,
.rc-icon.rc-hunt--sm::after {
  background-position: 66.67% 37.5%;
}
.rc-icon.incompatible::after,
.rc-icon.rc-incompatible::after {
  background-position: 66.67% 50%;
}
.rc-icon.indoor-outdoor::after,
.rc-icon.rc-indoor-outdoor::after {
  background-position: 0 75%;
}
.rc-icon.indoor-outdoor--sm::after,
.rc-icon.rc-indoor-outdoor--sm::after {
  background-position: 11.11% 75%;
}
.rc-icon.indoors::after,
.rc-icon.rc-indoors::after {
  background-position: 22.22% 75%;
}
.rc-icon.indoors--sm::after,
.rc-icon.rc-indoors--sm::after {
  background-position: 33.33% 75%;
}
.rc-icon.low-maintenance::after,
.rc-icon.rc-low-maintenance::after {
  background-position: 44.44% 75%;
}
.rc-icon.low-maintenance--sm::after,
.rc-icon.rc-low-maintenance--sm::after {
  background-position: 55.56% 75%;
}
.rc-icon.medicine::after,
.rc-icon.rc-medicine::after {
  background-position: 66.67% 75%;
}
.rc-icon.medicine--sm::after,
.rc-icon.rc-medicine--sm::after {
  background-position: 77.78% 0;
}
.rc-icon.microchip::after,
.rc-icon.rc-microchip::after {
  background-position: 77.78% 12.5%;
}
.rc-icon.microchip--sm::after,
.rc-icon.rc-microchip--sm::after {
  background-position: 77.78% 25%;
}
.rc-icon.need-space::after,
.rc-icon.rc-need-space::after {
  background-position: 77.78% 37.5%;
}
.rc-icon.need-space--sm::after,
.rc-icon.rc-need-space--sm::after {
  background-position: 77.78% 50%;
}
.rc-icon.no-alone::after,
.rc-icon.rc-no-alone::after {
  background-position: 77.78% 62.5%;
}
.rc-icon.no-alone--sm::after,
.rc-icon.rc-no-alone--sm::after {
  background-position: 77.78% 75%;
}
.rc-icon.no-garden::after,
.rc-icon.rc-no-garden::after {
  background-position: 0 87.5%;
}
.rc-icon.no-garden--sm::after,
.rc-icon.rc-no-garden--sm::after {
  background-position: 11.11% 87.5%;
}
.rc-icon.outdoor::after,
.rc-icon.rc-outdoor::after {
  background-position: 22.22% 87.5%;
}
.rc-icon.outdoor--sm::after,
.rc-icon.rc-outdoor--sm::after {
  background-position: 33.33% 87.5%;
}
.rc-icon.pairs::after,
.rc-icon.rc-pairs::after {
  background-position: 44.44% 87.5%;
}
.rc-icon.pairs--sm::after,
.rc-icon.rc-pairs--sm::after {
  background-position: 55.56% 87.5%;
}
.rc-icon.patient::after,
.rc-icon.rc-patient::after {
  background-position: 66.67% 87.5%;
}
.rc-icon.patient--sm::after,
.rc-icon.rc-patient--sm::after {
  background-position: 77.78% 87.5%;
}
.rc-icon.quiet-home::after,
.rc-icon.rc-quiet-home::after {
  background-position: 88.89% 0;
}
.rc-icon.quiet-home--sm::after,
.rc-icon.rc-quiet-home--sm::after {
  background-position: 88.89% 12.5%;
}
.rc-icon.rc-sleep::after,
.rc-icon.sleep::after {
  background-position: 88.89% 25%;
}
.rc-icon.rc-sleep--sm::after,
.rc-icon.sleep--sm::after {
  background-position: 88.89% 37.5%;
}
.rc-icon.rc-sm-home::after,
.rc-icon.sm-home::after {
  background-position: 88.89% 50%;
}
.rc-icon.rc-sm-home--sm::after,
.rc-icon.sm-home--sm::after {
  background-position: 88.89% 62.5%;
}
.rc-icon.rc-sporting::after,
.rc-icon.sporting::after {
  background-position: 88.89% 75%;
}
.rc-icon.rc-sporting--sm::after,
.rc-icon.sporting--sm::after {
  background-position: 88.89% 87.5%;
}
.rc-icon.rc-startled--sm::after,
.rc-icon.startled--sm::after {
  background-position: 11.11% 100%;
}
.rc-icon.rc-training::after,
.rc-icon.training::after {
  background-position: 22.22% 100%;
}
.rc-icon.rc-training--sm::after,
.rc-icon.training--sm::after {
  background-position: 33.33% 100%;
}
.rc-icon.rc-vaccination::after,
.rc-icon.vaccination::after {
  background-position: 44.44% 100%;
}
.rc-icon.rc-vaccination--sm::after,
.rc-icon.vaccination--sm::after {
  background-position: 55.56% 100%;
}
.rc-icon.rc-versatile::after,
.rc-icon.versatile::after {
  background-position: 66.67% 100%;
}
.rc-icon.rc-versatile--sm::after,
.rc-icon.versatile--sm::after {
  background-position: 77.78% 100%;
}
.rc-icon.rc-watchdog::after,
.rc-icon.watchdog::after {
  background-position: 88.89% 100%;
}
.rc-icon.actions::after,
.rc-icon.rc-actions::after {
  background-position: 10% 0;
}
.rc-icon.address::after,
.rc-icon.rc-address::after {
  background-position: 20% 70%;
}
.rc-icon.advice::after,
.rc-icon.rc-advice::after {
  background-position: 0 10%;
}
.rc-icon.advice--sm::after,
.rc-icon.rc-advice--sm::after {
  background-position: 10% 10%;
}
.rc-icon.arrow::after,
.rc-icon.rc-arrow::after {
  background-position: 20% 0;
}
.rc-icon.attachment::after,
.rc-icon.rc-attachment::after {
  background-position: 20% 10%;
}
.rc-icon.back::after,
.rc-icon.rc-back::after {
  background-position: 0 20%;
}
.rc-icon.bag::after,
.rc-icon.rc-bag::after {
  background-position: 10% 20%;
}
.rc-icon.basket::after,
.rc-icon.rc-basket::after {
  background-position: 20% 20%;
}
.rc-icon.basket--sm::after,
.rc-icon.rc-basket--sm::after {
  background-position: 30% 0;
}
.rc-icon.bookmark::after,
.rc-icon.rc-bookmark::after {
  background-position: 30% 10%;
}
.rc-icon.calendar::after,
.rc-icon.rc-calendar::after {
  background-position: 30% 20%;
}
.rc-icon.calendar--sm::after,
.rc-icon.rc-calendar--sm::after {
  background-position: 0 30%;
}
.rc-icon.cart::after,
.rc-icon.rc-cart::after {
  background-position: 10% 30%;
}
.rc-icon.cart--sm::after,
.rc-icon.rc-cart--sm::after {
  background-position: 20% 30%;
}
.rc-icon.clock::after,
.rc-icon.rc-clock::after {
  background-position: 30% 30%;
}
.rc-icon.clock--sm::after,
.rc-icon.rc-clock--sm::after {
  background-position: 40% 0;
}
.rc-icon.close::after,
.rc-icon.rc-close::after {
  background-position: 40% 10%;
}
.rc-icon.close--sm::after,
.rc-icon.rc-close--sm::after {
  background-position: 40% 20%;
}
.rc-icon.collapse::after,
.rc-icon.rc-collapse::after {
  background-position: 40% 30%;
}
.rc-icon.comment::after,
.rc-icon.rc-comment::after {
  background-position: 0 40%;
}
.rc-icon.computer::after,
.rc-icon.rc-computer::after {
  background-position: 10% 40%;
}
.rc-icon.confirmation--sm::after,
.rc-icon.rc-confirmation--sm::after {
  background-position: 20% 40%;
}
.rc-icon.contact::after,
.rc-icon.rc-contact::after {
  background-position: 30% 40%;
}
.rc-icon.contact--sm::after,
.rc-icon.rc-contact--sm::after {
  background-position: 40% 40%;
}
.rc-icon.delivery--sm::after,
.rc-icon.rc-delivery--sm::after {
  background-position: 50% 0;
}
.rc-icon.documents::after,
.rc-icon.rc-documents::after {
  background-position: 50% 10%;
}
.rc-icon.down::after,
.rc-icon.rc-down::after {
  background-position: 50% 20%;
}
.rc-icon.download::after,
.rc-icon.rc-download::after {
  background-position: 50% 30%;
}
.rc-icon.download--sm::after,
.rc-icon.rc-download--sm::after {
  background-position: 50% 40%;
}
.rc-icon.email::after,
.rc-icon.rc-email::after {
  background-position: 10% 50%;
}
.rc-icon.email--sm::after,
.rc-icon.rc-email--sm::after {
  background-position: 20% 50%;
}
.rc-icon.expand::after,
.rc-icon.rc-expand::after {
  background-position: 30% 50%;
}
.rc-icon.external::after,
.rc-icon.rc-external::after {
  background-position: 40% 50%;
}
.rc-icon.fast-forward::after,
.rc-icon.rc-fast-forward::after {
  background-position: 50% 50%;
}
.rc-icon.female::after,
.rc-icon.rc-female::after {
  background-position: 60% 0;
}
.rc-icon.filter::after,
.rc-icon.rc-filter::after {
  background-position: 60% 10%;
}
.rc-icon.food::after,
.rc-icon.rc-food::after {
  background-position: 60% 20%;
}
.rc-icon.food--sm::after,
.rc-icon.rc-food--sm::after {
  background-position: 60% 30%;
}
.rc-icon.food2::after,
.rc-icon.rc-food2::after {
  background-position: 60% 40%;
}
.rc-icon.forward::after,
.rc-icon.rc-forward::after {
  background-position: 60% 50%;
}
.rc-icon.grid-view::after,
.rc-icon.rc-grid-view::after {
  background-position: 0 60%;
}
.rc-icon.grid-view--sm::after,
.rc-icon.rc-grid-view--sm::after {
  background-position: 10% 60%;
}
.rc-icon.groomer::after,
.rc-icon.rc-groomer::after {
  background-position: 20% 60%;
}
.rc-icon.health::after,
.rc-icon.rc-health::after {
  background-position: 30% 60%;
}
.rc-icon.health--sm::after,
.rc-icon.rc-health--sm::after {
  background-position: 40% 60%;
}
.rc-icon.help::after,
.rc-icon.rc-help::after {
  background-position: 50% 60%;
}
.rc-icon.hide::after,
.rc-icon.rc-hide::after {
  background-position: 60% 60%;
}
.rc-icon.home::after,
.rc-icon.rc-home::after {
  background-position: 70% 0;
}
.rc-icon.home--sm::after,
.rc-icon.rc-home--sm::after {
  background-position: 70% 10%;
}
.rc-icon.image::after,
.rc-icon.rc-image::after {
  background-position: 70% 20%;
}
.rc-icon.info::after,
.rc-icon.rc-info::after {
  background-position: 70% 30%;
}
.rc-icon.knowledge::after,
.rc-icon.rc-knowledge::after {
  background-position: 70% 40%;
}
.rc-icon.left::after,
.rc-icon.rc-left::after {
  background-position: 70% 50%;
}
.rc-icon.like::after,
.rc-icon.rc-like::after {
  background-position: 70% 60%;
}
.rc-icon.list-view::after,
.rc-icon.rc-list-view::after {
  background-position: 0 70%;
}
.rc-icon.list-view--sm::after,
.rc-icon.rc-list-view--sm::after {
  background-position: 10% 70%;
}
.rc-icon.location::after,
.rc-icon.rc-location::after {
  background-position: 30% 70%;
}
.rc-icon.location2::after,
.rc-icon.rc-location2::after {
  background-position: 40% 70%;
}
.rc-icon.lock::after,
.rc-icon.rc-lock::after {
  background-position: 50% 70%;
}
.rc-icon.male::after,
.rc-icon.rc-male::after {
  background-position: 60% 70%;
}
.rc-icon.menu::after,
.rc-icon.rc-menu::after {
  background-position: 70% 70%;
}
.rc-icon.menu--sm::after,
.rc-icon.rc-menu--sm::after {
  background-position: 80% 0;
}
.rc-icon.minus::after,
.rc-icon.rc-minus::after {
  background-position: 80% 10%;
}
.rc-icon.mobile::after,
.rc-icon.rc-mobile::after {
  background-position: 80% 20%;
}
.rc-icon.mouse::after,
.rc-icon.rc-mouse::after {
  background-position: 80% 30%;
}
.rc-icon.new-window::after,
.rc-icon.rc-new-window::after {
  background-position: 80% 40%;
}
.rc-icon.new-window--sm::after,
.rc-icon.rc-new-window--sm::after {
  background-position: 80% 50%;
}
.rc-icon.news::after,
.rc-icon.rc-news::after {
  background-position: 80% 60%;
}
.rc-icon.nutritional::after,
.rc-icon.rc-nutritional::after {
  background-position: 80% 70%;
}
.rc-icon.nutritional--sm::after,
.rc-icon.rc-nutritional--sm::after {
  background-position: 0 80%;
}
.rc-icon.pause::after,
.rc-icon.rc-pause::after {
  background-position: 10% 80%;
}
.rc-icon.payment--sm::after,
.rc-icon.rc-payment--sm::after {
  background-position: 20% 80%;
}
.rc-icon.pdf::after,
.rc-icon.rc-pdf::after {
  background-position: 30% 80%;
}
.rc-icon.pharmacy::after,
.rc-icon.rc-pharmacy::after {
  background-position: 40% 80%;
}
.rc-icon.pharmacy--sm::after,
.rc-icon.rc-pharmacy--sm::after {
  background-position: 50% 80%;
}
.rc-icon.pin::after,
.rc-icon.rc-pin::after {
  background-position: 60% 80%;
}
.rc-icon.pin--sm::after,
.rc-icon.rc-pin--sm::after {
  background-position: 70% 80%;
}
.rc-icon.play::after,
.rc-icon.rc-play::after {
  background-position: 80% 80%;
}
.rc-icon.plus::after,
.rc-icon.rc-plus::after {
  background-position: 90% 0;
}
.rc-icon.print::after,
.rc-icon.rc-print::after {
  background-position: 90% 10%;
}
.rc-icon.quality::after,
.rc-icon.rc-quality::after {
  background-position: 90% 20%;
}
.rc-icon.rate::after,
.rc-icon.rc-rate::after {
  background-position: 90% 30%;
}
.rc-icon.rate-fill::after,
.rc-icon.rc-rate-fill::after {
  background-position: 89.98% 40%;
}
.rc-icon.rc-refresh::after,
.rc-icon.refresh::after {
  background-position: 90% 50%;
}
.rc-icon.rc-rewind::after,
.rc-icon.rewind::after {
  background-position: 90% 60%;
}
.rc-icon.rc-right::after,
.rc-icon.right::after {
  background-position: 90% 70%;
}
.rc-icon.rc-rss::after,
.rc-icon.rss::after {
  background-position: 90% 80%;
}
.rc-icon.rc-search::after,
.rc-icon.search::after {
  background-position: 0 90%;
}
.rc-icon.rc-search--sm::after,
.rc-icon.search--sm::after {
  background-position: 10% 90%;
}
.rc-icon.rc-settings::after,
.rc-icon.settings::after {
  background-position: 20% 90%;
}
.rc-icon.rc-share::after,
.rc-icon.share::after {
  background-position: 30% 90%;
}
.rc-icon.rc-shelter::after,
.rc-icon.shelter::after {
  background-position: 40% 90%;
}
.rc-icon.rc-shop::after,
.rc-icon.shop::after {
  background-position: 50% 90%;
}
.rc-icon.rc-show::after,
.rc-icon.show::after {
  background-position: 60% 90%;
}
.rc-icon.rc-show--sm::after,
.rc-icon.show--sm::after {
  background-position: 70% 90%;
}
.rc-icon.rc-skip::after,
.rc-icon.skip::after {
  background-position: 80% 90%;
}
.rc-icon.rc-stop::after,
.rc-icon.stop::after {
  background-position: 90% 90%;
}
.rc-icon.rc-tablet::after,
.rc-icon.tablet::after {
  background-position: 100% 10%;
}
.rc-icon.rc-text-decrease::after,
.rc-icon.text-decrease::after {
  background-position: 100% 20%;
}
.rc-icon.rc-text-increase::after,
.rc-icon.text-increase::after {
  background-position: 100% 30%;
}
.rc-icon.rc-unlock::after,
.rc-icon.unlock::after {
  background-position: 100% 40%;
}
.rc-icon.rc-up::after,
.rc-icon.up::after {
  background-position: 100% 50%;
}
.rc-icon.rc-user::after,
.rc-icon.user::after {
  background-position: 100% 60%;
}
.rc-icon.rc-user--sm::after,
.rc-icon.user--sm::after {
  background-position: 100% 70%;
}
.rc-icon.rc-user-add::after,
.rc-icon.user-add::after {
  background-position: 100% 80%;
}
.rc-icon.rc-vet::after,
.rc-icon.vet::after {
  background-position: 100% 90%;
}
.rc-icon.rc-video::after,
.rc-icon.video::after {
  background-position: 10% 100%;
}
.rc-icon.rc-volume-off::after,
.rc-icon.volume-off::after {
  background-position: 20% 100%;
}
.rc-icon.rc-volume-on::after,
.rc-icon.volume-on::after {
  background-position: 30% 100%;
}
.rc-icon.rc-weight::after,
.rc-icon.weight::after {
  background-position: 40% 100%;
}
.rc-icon.rc-weight--sm::after,
.rc-icon.weight--sm::after {
  background-position: 50% 100%;
}
.rc-icon.rc-attention--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-cat-tree--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-children--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-deafness--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-enjoys-training--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-exercise--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-experience--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-family--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-first-time--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-friendly--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-grooming--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-guard-dog--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-heat--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-human-interaction--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-hunt--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-incompatible--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-indoor-outdoor--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-indoors--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-low-maintenance--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-need-space--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-no-alone--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-no-garden--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-outdoor--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-pairs--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-patient--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-quiet-home--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-sm-home--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-sporting--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-startled--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-training--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-versatile--xs[class*='--xs'].rc-iconography::after,
.rc-icon.rc-watchdog--xs[class*='--xs'].rc-iconography::after {
  width: 24px;
}
.rc-icon.rc-attention--xs.interactive.iconography:active::after,
.rc-icon.rc-attention--xs.interactive.iconography:focus::after,
.rc-icon.rc-attention--xs.interactive.iconography:hover::after,
.rc-icon.rc-attention--xs.rc-brand1::after,
.rc-icon.rc-attention--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-attention--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-attention--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-attention--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-cat-tree--xs.interactive.iconography:active::after,
.rc-icon.rc-cat-tree--xs.interactive.iconography:focus::after,
.rc-icon.rc-cat-tree--xs.interactive.iconography:hover::after,
.rc-icon.rc-cat-tree--xs.rc-brand1::after,
.rc-icon.rc-cat-tree--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-cat-tree--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-cat-tree--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-cat-tree--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-children--xs.interactive.iconography:active::after,
.rc-icon.rc-children--xs.interactive.iconography:focus::after,
.rc-icon.rc-children--xs.interactive.iconography:hover::after,
.rc-icon.rc-children--xs.rc-brand1::after,
.rc-icon.rc-children--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-children--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-children--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-children--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-deafness--xs.interactive.iconography:active::after,
.rc-icon.rc-deafness--xs.interactive.iconography:focus::after,
.rc-icon.rc-deafness--xs.interactive.iconography:hover::after,
.rc-icon.rc-deafness--xs.rc-brand1::after,
.rc-icon.rc-deafness--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-deafness--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-deafness--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-deafness--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-enjoys-training--xs.interactive.iconography:active::after,
.rc-icon.rc-enjoys-training--xs.interactive.iconography:focus::after,
.rc-icon.rc-enjoys-training--xs.interactive.iconography:hover::after,
.rc-icon.rc-enjoys-training--xs.rc-brand1::after,
.rc-icon.rc-enjoys-training--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-enjoys-training--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-enjoys-training--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-enjoys-training--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-exercise--xs.interactive.iconography:active::after,
.rc-icon.rc-exercise--xs.interactive.iconography:focus::after,
.rc-icon.rc-exercise--xs.interactive.iconography:hover::after,
.rc-icon.rc-exercise--xs.rc-brand1::after,
.rc-icon.rc-exercise--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-exercise--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-exercise--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-exercise--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-experience--xs.interactive.iconography:active::after,
.rc-icon.rc-experience--xs.interactive.iconography:focus::after,
.rc-icon.rc-experience--xs.interactive.iconography:hover::after,
.rc-icon.rc-experience--xs.rc-brand1::after,
.rc-icon.rc-experience--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-experience--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-experience--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-experience--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-family--xs.interactive.iconography:active::after,
.rc-icon.rc-family--xs.interactive.iconography:focus::after,
.rc-icon.rc-family--xs.interactive.iconography:hover::after,
.rc-icon.rc-family--xs.rc-brand1::after,
.rc-icon.rc-family--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-family--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-family--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-family--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-first-time--xs.interactive.iconography:active::after,
.rc-icon.rc-first-time--xs.interactive.iconography:focus::after,
.rc-icon.rc-first-time--xs.interactive.iconography:hover::after,
.rc-icon.rc-first-time--xs.rc-brand1::after,
.rc-icon.rc-first-time--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-first-time--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-first-time--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-first-time--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-friendly--xs.interactive.iconography:active::after,
.rc-icon.rc-friendly--xs.interactive.iconography:focus::after,
.rc-icon.rc-friendly--xs.interactive.iconography:hover::after,
.rc-icon.rc-friendly--xs.rc-brand1::after,
.rc-icon.rc-friendly--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-friendly--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-friendly--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-friendly--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-grooming--xs.interactive.iconography:active::after,
.rc-icon.rc-grooming--xs.interactive.iconography:focus::after,
.rc-icon.rc-grooming--xs.interactive.iconography:hover::after,
.rc-icon.rc-grooming--xs.rc-brand1::after,
.rc-icon.rc-grooming--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-grooming--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-grooming--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-grooming--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-guard-dog--xs.interactive.iconography:active::after,
.rc-icon.rc-guard-dog--xs.interactive.iconography:focus::after,
.rc-icon.rc-guard-dog--xs.interactive.iconography:hover::after,
.rc-icon.rc-guard-dog--xs.rc-brand1::after,
.rc-icon.rc-guard-dog--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-guard-dog--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-guard-dog--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-guard-dog--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-heat--xs.interactive.iconography:active::after,
.rc-icon.rc-heat--xs.interactive.iconography:focus::after,
.rc-icon.rc-heat--xs.interactive.iconography:hover::after,
.rc-icon.rc-heat--xs.rc-brand1::after,
.rc-icon.rc-heat--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-heat--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-heat--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-heat--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-human-interaction--xs.interactive.iconography:active::after,
.rc-icon.rc-human-interaction--xs.interactive.iconography:focus::after,
.rc-icon.rc-human-interaction--xs.interactive.iconography:hover::after,
.rc-icon.rc-human-interaction--xs.rc-brand1::after,
.rc-icon.rc-human-interaction--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-human-interaction--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-human-interaction--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-human-interaction--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-hunt--xs.interactive.iconography:active::after,
.rc-icon.rc-hunt--xs.interactive.iconography:focus::after,
.rc-icon.rc-hunt--xs.interactive.iconography:hover::after,
.rc-icon.rc-hunt--xs.rc-brand1::after,
.rc-icon.rc-hunt--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-hunt--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-hunt--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-hunt--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-incompatible--xs.interactive.iconography:active::after,
.rc-icon.rc-incompatible--xs.interactive.iconography:focus::after,
.rc-icon.rc-incompatible--xs.interactive.iconography:hover::after,
.rc-icon.rc-incompatible--xs.rc-brand1::after,
.rc-icon.rc-incompatible--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-incompatible--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-incompatible--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-incompatible--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-indoor-outdoor--xs.interactive.iconography:active::after,
.rc-icon.rc-indoor-outdoor--xs.interactive.iconography:focus::after,
.rc-icon.rc-indoor-outdoor--xs.interactive.iconography:hover::after,
.rc-icon.rc-indoor-outdoor--xs.rc-brand1::after,
.rc-icon.rc-indoor-outdoor--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-indoor-outdoor--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-indoor-outdoor--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-indoor-outdoor--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-indoors--xs.interactive.iconography:active::after,
.rc-icon.rc-indoors--xs.interactive.iconography:focus::after,
.rc-icon.rc-indoors--xs.interactive.iconography:hover::after,
.rc-icon.rc-indoors--xs.rc-brand1::after,
.rc-icon.rc-indoors--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-indoors--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-indoors--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-indoors--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-low-maintenance--xs.interactive.iconography:active::after,
.rc-icon.rc-low-maintenance--xs.interactive.iconography:focus::after,
.rc-icon.rc-low-maintenance--xs.interactive.iconography:hover::after,
.rc-icon.rc-low-maintenance--xs.rc-brand1::after,
.rc-icon.rc-low-maintenance--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-low-maintenance--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-low-maintenance--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-low-maintenance--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-need-space--xs.interactive.iconography:active::after,
.rc-icon.rc-need-space--xs.interactive.iconography:focus::after,
.rc-icon.rc-need-space--xs.interactive.iconography:hover::after,
.rc-icon.rc-need-space--xs.rc-brand1::after,
.rc-icon.rc-need-space--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-need-space--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-need-space--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-need-space--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-no-alone--xs.interactive.iconography:active::after,
.rc-icon.rc-no-alone--xs.interactive.iconography:focus::after,
.rc-icon.rc-no-alone--xs.interactive.iconography:hover::after,
.rc-icon.rc-no-alone--xs.rc-brand1::after,
.rc-icon.rc-no-alone--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-no-alone--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-no-alone--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-no-alone--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-no-garden--xs.interactive.iconography:active::after,
.rc-icon.rc-no-garden--xs.interactive.iconography:focus::after,
.rc-icon.rc-no-garden--xs.interactive.iconography:hover::after,
.rc-icon.rc-no-garden--xs.rc-brand1::after,
.rc-icon.rc-no-garden--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-no-garden--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-no-garden--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-no-garden--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-outdoor--xs.interactive.iconography:active::after,
.rc-icon.rc-outdoor--xs.interactive.iconography:focus::after,
.rc-icon.rc-outdoor--xs.interactive.iconography:hover::after,
.rc-icon.rc-outdoor--xs.rc-brand1::after,
.rc-icon.rc-outdoor--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-outdoor--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-outdoor--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-outdoor--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-pairs--xs.interactive.iconography:active::after,
.rc-icon.rc-pairs--xs.interactive.iconography:focus::after,
.rc-icon.rc-pairs--xs.interactive.iconography:hover::after,
.rc-icon.rc-pairs--xs.rc-brand1::after,
.rc-icon.rc-pairs--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-pairs--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-pairs--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-pairs--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-patient--xs.interactive.iconography:active::after,
.rc-icon.rc-patient--xs.interactive.iconography:focus::after,
.rc-icon.rc-patient--xs.interactive.iconography:hover::after,
.rc-icon.rc-patient--xs.rc-brand1::after,
.rc-icon.rc-patient--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-patient--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-patient--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-patient--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-quiet-home--xs.interactive.iconography:active::after,
.rc-icon.rc-quiet-home--xs.interactive.iconography:focus::after,
.rc-icon.rc-quiet-home--xs.interactive.iconography:hover::after,
.rc-icon.rc-quiet-home--xs.rc-brand1::after,
.rc-icon.rc-quiet-home--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-quiet-home--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-quiet-home--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-quiet-home--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-sm-home--xs.interactive.iconography:active::after,
.rc-icon.rc-sm-home--xs.interactive.iconography:focus::after,
.rc-icon.rc-sm-home--xs.interactive.iconography:hover::after,
.rc-icon.rc-sm-home--xs.rc-brand1::after,
.rc-icon.rc-sm-home--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-sm-home--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-sm-home--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-sm-home--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-sporting--xs.interactive.iconography:active::after,
.rc-icon.rc-sporting--xs.interactive.iconography:focus::after,
.rc-icon.rc-sporting--xs.interactive.iconography:hover::after,
.rc-icon.rc-sporting--xs.rc-brand1::after,
.rc-icon.rc-sporting--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-sporting--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-sporting--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-sporting--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-startled--xs.interactive.iconography:active::after,
.rc-icon.rc-startled--xs.interactive.iconography:focus::after,
.rc-icon.rc-startled--xs.interactive.iconography:hover::after,
.rc-icon.rc-startled--xs.rc-brand1::after,
.rc-icon.rc-startled--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-startled--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-startled--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-startled--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-training--xs.interactive.iconography:active::after,
.rc-icon.rc-training--xs.interactive.iconography:focus::after,
.rc-icon.rc-training--xs.interactive.iconography:hover::after,
.rc-icon.rc-training--xs.rc-brand1::after,
.rc-icon.rc-training--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-training--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-training--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-training--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-versatile--xs.interactive.iconography:active::after,
.rc-icon.rc-versatile--xs.interactive.iconography:focus::after,
.rc-icon.rc-versatile--xs.interactive.iconography:hover::after,
.rc-icon.rc-versatile--xs.rc-brand1::after,
.rc-icon.rc-versatile--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-versatile--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-versatile--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-versatile--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-watchdog--xs.interactive.iconography:active::after,
.rc-icon.rc-watchdog--xs.interactive.iconography:focus::after,
.rc-icon.rc-watchdog--xs.interactive.iconography:hover::after,
.rc-icon.rc-watchdog--xs.rc-brand1::after,
.rc-icon.rc-watchdog--xs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-watchdog--xs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-watchdog--xs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-watchdog--xs[class*='--xs'].rc-brand1::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--breed--brand1--xs.svg);
}
.rc-icon.rc-attention--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-cat-tree--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-children--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-deafness--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-enjoys-training--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-exercise--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-experience--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-family--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-first-time--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-friendly--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-grooming--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-guard-dog--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-heat--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-human-interaction--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-hunt--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-incompatible--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-indoor-outdoor--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-indoors--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-low-maintenance--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-need-space--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-no-alone--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-no-garden--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-outdoor--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-pairs--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-patient--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-quiet-home--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-sm-home--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-sporting--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-startled--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-training--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-versatile--xs[class*='--xs'].rc-brand1::after,
.rc-icon.rc-watchdog--xs[class*='--xs'].rc-brand1::after {
  width: 24px;
}
.rc-icon.rc-attention--xs.rc-brand3::after,
.rc-icon.rc-attention--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-cat-tree--xs.rc-brand3::after,
.rc-icon.rc-cat-tree--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-children--xs.rc-brand3::after,
.rc-icon.rc-children--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-deafness--xs.rc-brand3::after,
.rc-icon.rc-deafness--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-enjoys-training--xs.rc-brand3::after,
.rc-icon.rc-enjoys-training--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-exercise--xs.rc-brand3::after,
.rc-icon.rc-exercise--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-experience--xs.rc-brand3::after,
.rc-icon.rc-experience--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-family--xs.rc-brand3::after,
.rc-icon.rc-family--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-first-time--xs.rc-brand3::after,
.rc-icon.rc-first-time--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-friendly--xs.rc-brand3::after,
.rc-icon.rc-friendly--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-grooming--xs.rc-brand3::after,
.rc-icon.rc-grooming--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-guard-dog--xs.rc-brand3::after,
.rc-icon.rc-guard-dog--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-heat--xs.rc-brand3::after,
.rc-icon.rc-heat--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-human-interaction--xs.rc-brand3::after,
.rc-icon.rc-human-interaction--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-hunt--xs.rc-brand3::after,
.rc-icon.rc-hunt--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-incompatible--xs.rc-brand3::after,
.rc-icon.rc-incompatible--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-indoor-outdoor--xs.rc-brand3::after,
.rc-icon.rc-indoor-outdoor--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-indoors--xs.rc-brand3::after,
.rc-icon.rc-indoors--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-low-maintenance--xs.rc-brand3::after,
.rc-icon.rc-low-maintenance--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-need-space--xs.rc-brand3::after,
.rc-icon.rc-need-space--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-no-alone--xs.rc-brand3::after,
.rc-icon.rc-no-alone--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-no-garden--xs.rc-brand3::after,
.rc-icon.rc-no-garden--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-outdoor--xs.rc-brand3::after,
.rc-icon.rc-outdoor--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-pairs--xs.rc-brand3::after,
.rc-icon.rc-pairs--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-patient--xs.rc-brand3::after,
.rc-icon.rc-patient--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-quiet-home--xs.rc-brand3::after,
.rc-icon.rc-quiet-home--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-sm-home--xs.rc-brand3::after,
.rc-icon.rc-sm-home--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-sporting--xs.rc-brand3::after,
.rc-icon.rc-sporting--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-startled--xs.rc-brand3::after,
.rc-icon.rc-startled--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-training--xs.rc-brand3::after,
.rc-icon.rc-training--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-versatile--xs.rc-brand3::after,
.rc-icon.rc-versatile--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-watchdog--xs.rc-brand3::after,
.rc-icon.rc-watchdog--xs[class*='--xs'].rc-brand3::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--breed--brand3--xs.svg);
}
.rc-icon.rc-attention--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-cat-tree--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-children--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-deafness--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-enjoys-training--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-exercise--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-experience--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-family--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-first-time--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-friendly--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-grooming--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-guard-dog--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-heat--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-human-interaction--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-hunt--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-incompatible--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-indoor-outdoor--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-indoors--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-low-maintenance--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-need-space--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-no-alone--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-no-garden--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-outdoor--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-pairs--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-patient--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-quiet-home--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-sm-home--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-sporting--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-startled--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-training--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-versatile--xs[class*='--xs'].rc-brand3::after,
.rc-icon.rc-watchdog--xs[class*='--xs'].rc-brand3::after {
  width: 24px;
}
.rc-icon.rc-attention--sm.rc-iconography::after,
.rc-icon.rc-attention.rc-iconography::after,
.rc-icon.rc-ball--sm.rc-iconography::after,
.rc-icon.rc-ball.rc-iconography::after,
.rc-icon.rc-behavior--sm.rc-iconography::after,
.rc-icon.rc-behavior.rc-iconography::after,
.rc-icon.rc-bug--sm.rc-iconography::after,
.rc-icon.rc-bug.rc-iconography::after,
.rc-icon.rc-cat-tree--sm.rc-iconography::after,
.rc-icon.rc-cat-tree.rc-iconography::after,
.rc-icon.rc-children--sm.rc-iconography::after,
.rc-icon.rc-children.rc-iconography::after,
.rc-icon.rc-collar--sm.rc-iconography::after,
.rc-icon.rc-collar.rc-iconography::after,
.rc-icon.rc-deafness--sm.rc-iconography::after,
.rc-icon.rc-deafness.rc-iconography::after,
.rc-icon.rc-digestion--sm.rc-iconography::after,
.rc-icon.rc-digestion.rc-iconography::after,
.rc-icon.rc-enjoys-training--sm.rc-iconography::after,
.rc-icon.rc-enjoys-training.rc-iconography::after,
.rc-icon.rc-exercise--sm.rc-iconography::after,
.rc-icon.rc-exercise.rc-iconography::after,
.rc-icon.rc-experience--sm.rc-iconography::after,
.rc-icon.rc-experience.rc-iconography::after,
.rc-icon.rc-family--sm.rc-iconography::after,
.rc-icon.rc-family.rc-iconography::after,
.rc-icon.rc-first-time--sm.rc-iconography::after,
.rc-icon.rc-first-time.rc-iconography::after,
.rc-icon.rc-friendly--sm.rc-iconography::after,
.rc-icon.rc-friendly.rc-iconography::after,
.rc-icon.rc-grooming--sm.rc-iconography::after,
.rc-icon.rc-grooming.rc-iconography::after,
.rc-icon.rc-guard-dog--sm.rc-iconography::after,
.rc-icon.rc-guard-dog.rc-iconography::after,
.rc-icon.rc-heat--sm.rc-iconography::after,
.rc-icon.rc-heat.rc-iconography::after,
.rc-icon.rc-human-interaction--sm.rc-iconography::after,
.rc-icon.rc-human-interaction.rc-iconography::after,
.rc-icon.rc-hunt--sm.rc-iconography::after,
.rc-icon.rc-hunt.rc-iconography::after,
.rc-icon.rc-incompatible--sm.rc-iconography::after,
.rc-icon.rc-incompatible.rc-iconography::after,
.rc-icon.rc-indoor-outdoor--sm.rc-iconography::after,
.rc-icon.rc-indoor-outdoor.rc-iconography::after,
.rc-icon.rc-indoors--sm.rc-iconography::after,
.rc-icon.rc-indoors.rc-iconography::after,
.rc-icon.rc-low-maintenance--sm.rc-iconography::after,
.rc-icon.rc-low-maintenance.rc-iconography::after,
.rc-icon.rc-medicine--sm.rc-iconography::after,
.rc-icon.rc-medicine.rc-iconography::after,
.rc-icon.rc-microchip--sm.rc-iconography::after,
.rc-icon.rc-microchip.rc-iconography::after,
.rc-icon.rc-need-space--sm.rc-iconography::after,
.rc-icon.rc-need-space.rc-iconography::after,
.rc-icon.rc-no-alone--sm.rc-iconography::after,
.rc-icon.rc-no-alone.rc-iconography::after,
.rc-icon.rc-no-garden--sm.rc-iconography::after,
.rc-icon.rc-no-garden.rc-iconography::after,
.rc-icon.rc-outdoor--sm.rc-iconography::after,
.rc-icon.rc-outdoor.rc-iconography::after,
.rc-icon.rc-pairs--sm.rc-iconography::after,
.rc-icon.rc-pairs.rc-iconography::after,
.rc-icon.rc-patient--sm.rc-iconography::after,
.rc-icon.rc-patient.rc-iconography::after,
.rc-icon.rc-quiet-home--sm.rc-iconography::after,
.rc-icon.rc-quiet-home.rc-iconography::after,
.rc-icon.rc-sleep--sm.rc-iconography::after,
.rc-icon.rc-sleep.rc-iconography::after,
.rc-icon.rc-sm-home--sm.rc-iconography::after,
.rc-icon.rc-sm-home.rc-iconography::after,
.rc-icon.rc-sporting--sm.rc-iconography::after,
.rc-icon.rc-sporting.rc-iconography::after,
.rc-icon.rc-startled--sm.rc-iconography::after,
.rc-icon.rc-startled.rc-iconography::after,
.rc-icon.rc-training--sm.rc-iconography::after,
.rc-icon.rc-training.rc-iconography::after,
.rc-icon.rc-vaccination--sm.rc-iconography::after,
.rc-icon.rc-vaccination.rc-iconography::after,
.rc-icon.rc-versatile--sm.rc-iconography::after,
.rc-icon.rc-versatile.rc-iconography::after,
.rc-icon.rc-watchdog--sm.rc-iconography::after,
.rc-icon.rc-watchdog.rc-iconography::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--breed--iconography.svg);
}
.rc-icon.rc-attention--sm.interactive.iconography:active::after,
.rc-icon.rc-attention--sm.interactive.iconography:focus::after,
.rc-icon.rc-attention--sm.interactive.iconography:hover::after,
.rc-icon.rc-attention--sm.rc-brand1::after,
.rc-icon.rc-attention--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-attention--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-attention--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-attention.interactive.iconography:active::after,
.rc-icon.rc-attention.interactive.iconography:focus::after,
.rc-icon.rc-attention.interactive.iconography:hover::after,
.rc-icon.rc-attention.rc-brand1::after,
.rc-icon.rc-attention.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-attention.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-attention.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-ball--sm.interactive.iconography:active::after,
.rc-icon.rc-ball--sm.interactive.iconography:focus::after,
.rc-icon.rc-ball--sm.interactive.iconography:hover::after,
.rc-icon.rc-ball--sm.rc-brand1::after,
.rc-icon.rc-ball--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-ball--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-ball--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-ball.interactive.iconography:active::after,
.rc-icon.rc-ball.interactive.iconography:focus::after,
.rc-icon.rc-ball.interactive.iconography:hover::after,
.rc-icon.rc-ball.rc-brand1::after,
.rc-icon.rc-ball.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-ball.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-ball.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-behavior--sm.interactive.iconography:active::after,
.rc-icon.rc-behavior--sm.interactive.iconography:focus::after,
.rc-icon.rc-behavior--sm.interactive.iconography:hover::after,
.rc-icon.rc-behavior--sm.rc-brand1::after,
.rc-icon.rc-behavior--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-behavior--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-behavior--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-behavior.interactive.iconography:active::after,
.rc-icon.rc-behavior.interactive.iconography:focus::after,
.rc-icon.rc-behavior.interactive.iconography:hover::after,
.rc-icon.rc-behavior.rc-brand1::after,
.rc-icon.rc-behavior.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-behavior.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-behavior.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-bug--sm.interactive.iconography:active::after,
.rc-icon.rc-bug--sm.interactive.iconography:focus::after,
.rc-icon.rc-bug--sm.interactive.iconography:hover::after,
.rc-icon.rc-bug--sm.rc-brand1::after,
.rc-icon.rc-bug--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-bug--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-bug--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-bug.interactive.iconography:active::after,
.rc-icon.rc-bug.interactive.iconography:focus::after,
.rc-icon.rc-bug.interactive.iconography:hover::after,
.rc-icon.rc-bug.rc-brand1::after,
.rc-icon.rc-bug.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-bug.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-bug.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-cat-tree--sm.interactive.iconography:active::after,
.rc-icon.rc-cat-tree--sm.interactive.iconography:focus::after,
.rc-icon.rc-cat-tree--sm.interactive.iconography:hover::after,
.rc-icon.rc-cat-tree--sm.rc-brand1::after,
.rc-icon.rc-cat-tree--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-cat-tree--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-cat-tree--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-cat-tree.interactive.iconography:active::after,
.rc-icon.rc-cat-tree.interactive.iconography:focus::after,
.rc-icon.rc-cat-tree.interactive.iconography:hover::after,
.rc-icon.rc-cat-tree.rc-brand1::after,
.rc-icon.rc-cat-tree.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-cat-tree.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-cat-tree.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-children--sm.interactive.iconography:active::after,
.rc-icon.rc-children--sm.interactive.iconography:focus::after,
.rc-icon.rc-children--sm.interactive.iconography:hover::after,
.rc-icon.rc-children--sm.rc-brand1::after,
.rc-icon.rc-children--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-children--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-children--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-children.interactive.iconography:active::after,
.rc-icon.rc-children.interactive.iconography:focus::after,
.rc-icon.rc-children.interactive.iconography:hover::after,
.rc-icon.rc-children.rc-brand1::after,
.rc-icon.rc-children.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-children.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-children.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-collar--sm.interactive.iconography:active::after,
.rc-icon.rc-collar--sm.interactive.iconography:focus::after,
.rc-icon.rc-collar--sm.interactive.iconography:hover::after,
.rc-icon.rc-collar--sm.rc-brand1::after,
.rc-icon.rc-collar--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-collar--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-collar--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-collar.interactive.iconography:active::after,
.rc-icon.rc-collar.interactive.iconography:focus::after,
.rc-icon.rc-collar.interactive.iconography:hover::after,
.rc-icon.rc-collar.rc-brand1::after,
.rc-icon.rc-collar.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-collar.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-collar.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-deafness--sm.interactive.iconography:active::after,
.rc-icon.rc-deafness--sm.interactive.iconography:focus::after,
.rc-icon.rc-deafness--sm.interactive.iconography:hover::after,
.rc-icon.rc-deafness--sm.rc-brand1::after,
.rc-icon.rc-deafness--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-deafness--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-deafness--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-deafness.interactive.iconography:active::after,
.rc-icon.rc-deafness.interactive.iconography:focus::after,
.rc-icon.rc-deafness.interactive.iconography:hover::after,
.rc-icon.rc-deafness.rc-brand1::after,
.rc-icon.rc-deafness.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-deafness.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-deafness.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-digestion--sm.interactive.iconography:active::after,
.rc-icon.rc-digestion--sm.interactive.iconography:focus::after,
.rc-icon.rc-digestion--sm.interactive.iconography:hover::after,
.rc-icon.rc-digestion--sm.rc-brand1::after,
.rc-icon.rc-digestion--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-digestion--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-digestion--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-digestion.interactive.iconography:active::after,
.rc-icon.rc-digestion.interactive.iconography:focus::after,
.rc-icon.rc-digestion.interactive.iconography:hover::after,
.rc-icon.rc-digestion.rc-brand1::after,
.rc-icon.rc-digestion.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-digestion.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-digestion.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-enjoys-training--sm.interactive.iconography:active::after,
.rc-icon.rc-enjoys-training--sm.interactive.iconography:focus::after,
.rc-icon.rc-enjoys-training--sm.interactive.iconography:hover::after,
.rc-icon.rc-enjoys-training--sm.rc-brand1::after,
.rc-icon.rc-enjoys-training--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-enjoys-training--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-enjoys-training--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-enjoys-training.interactive.iconography:active::after,
.rc-icon.rc-enjoys-training.interactive.iconography:focus::after,
.rc-icon.rc-enjoys-training.interactive.iconography:hover::after,
.rc-icon.rc-enjoys-training.rc-brand1::after,
.rc-icon.rc-enjoys-training.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-enjoys-training.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-enjoys-training.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-exercise--sm.interactive.iconography:active::after,
.rc-icon.rc-exercise--sm.interactive.iconography:focus::after,
.rc-icon.rc-exercise--sm.interactive.iconography:hover::after,
.rc-icon.rc-exercise--sm.rc-brand1::after,
.rc-icon.rc-exercise--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-exercise--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-exercise--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-exercise.interactive.iconography:active::after,
.rc-icon.rc-exercise.interactive.iconography:focus::after,
.rc-icon.rc-exercise.interactive.iconography:hover::after,
.rc-icon.rc-exercise.rc-brand1::after,
.rc-icon.rc-exercise.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-exercise.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-exercise.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-experience--sm.interactive.iconography:active::after,
.rc-icon.rc-experience--sm.interactive.iconography:focus::after,
.rc-icon.rc-experience--sm.interactive.iconography:hover::after,
.rc-icon.rc-experience--sm.rc-brand1::after,
.rc-icon.rc-experience--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-experience--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-experience--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-experience.interactive.iconography:active::after,
.rc-icon.rc-experience.interactive.iconography:focus::after,
.rc-icon.rc-experience.interactive.iconography:hover::after,
.rc-icon.rc-experience.rc-brand1::after,
.rc-icon.rc-experience.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-experience.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-experience.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-family--sm.interactive.iconography:active::after,
.rc-icon.rc-family--sm.interactive.iconography:focus::after,
.rc-icon.rc-family--sm.interactive.iconography:hover::after,
.rc-icon.rc-family--sm.rc-brand1::after,
.rc-icon.rc-family--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-family--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-family--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-family.interactive.iconography:active::after,
.rc-icon.rc-family.interactive.iconography:focus::after,
.rc-icon.rc-family.interactive.iconography:hover::after,
.rc-icon.rc-family.rc-brand1::after,
.rc-icon.rc-family.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-family.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-family.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-first-time--sm.interactive.iconography:active::after,
.rc-icon.rc-first-time--sm.interactive.iconography:focus::after,
.rc-icon.rc-first-time--sm.interactive.iconography:hover::after,
.rc-icon.rc-first-time--sm.rc-brand1::after,
.rc-icon.rc-first-time--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-first-time--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-first-time--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-first-time.interactive.iconography:active::after,
.rc-icon.rc-first-time.interactive.iconography:focus::after,
.rc-icon.rc-first-time.interactive.iconography:hover::after,
.rc-icon.rc-first-time.rc-brand1::after,
.rc-icon.rc-first-time.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-first-time.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-first-time.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-friendly--sm.interactive.iconography:active::after,
.rc-icon.rc-friendly--sm.interactive.iconography:focus::after,
.rc-icon.rc-friendly--sm.interactive.iconography:hover::after,
.rc-icon.rc-friendly--sm.rc-brand1::after,
.rc-icon.rc-friendly--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-friendly--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-friendly--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-friendly.interactive.iconography:active::after,
.rc-icon.rc-friendly.interactive.iconography:focus::after,
.rc-icon.rc-friendly.interactive.iconography:hover::after,
.rc-icon.rc-friendly.rc-brand1::after,
.rc-icon.rc-friendly.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-friendly.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-friendly.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-grooming--sm.interactive.iconography:active::after,
.rc-icon.rc-grooming--sm.interactive.iconography:focus::after,
.rc-icon.rc-grooming--sm.interactive.iconography:hover::after,
.rc-icon.rc-grooming--sm.rc-brand1::after,
.rc-icon.rc-grooming--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-grooming--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-grooming--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-grooming.interactive.iconography:active::after,
.rc-icon.rc-grooming.interactive.iconography:focus::after,
.rc-icon.rc-grooming.interactive.iconography:hover::after,
.rc-icon.rc-grooming.rc-brand1::after,
.rc-icon.rc-grooming.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-grooming.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-grooming.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-guard-dog--sm.interactive.iconography:active::after,
.rc-icon.rc-guard-dog--sm.interactive.iconography:focus::after,
.rc-icon.rc-guard-dog--sm.interactive.iconography:hover::after,
.rc-icon.rc-guard-dog--sm.rc-brand1::after,
.rc-icon.rc-guard-dog--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-guard-dog--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-guard-dog--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-guard-dog.interactive.iconography:active::after,
.rc-icon.rc-guard-dog.interactive.iconography:focus::after,
.rc-icon.rc-guard-dog.interactive.iconography:hover::after,
.rc-icon.rc-guard-dog.rc-brand1::after,
.rc-icon.rc-guard-dog.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-guard-dog.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-guard-dog.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-heat--sm.interactive.iconography:active::after,
.rc-icon.rc-heat--sm.interactive.iconography:focus::after,
.rc-icon.rc-heat--sm.interactive.iconography:hover::after,
.rc-icon.rc-heat--sm.rc-brand1::after,
.rc-icon.rc-heat--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-heat--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-heat--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-heat.interactive.iconography:active::after,
.rc-icon.rc-heat.interactive.iconography:focus::after,
.rc-icon.rc-heat.interactive.iconography:hover::after,
.rc-icon.rc-heat.rc-brand1::after,
.rc-icon.rc-heat.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-heat.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-heat.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-human-interaction--sm.interactive.iconography:active::after,
.rc-icon.rc-human-interaction--sm.interactive.iconography:focus::after,
.rc-icon.rc-human-interaction--sm.interactive.iconography:hover::after,
.rc-icon.rc-human-interaction--sm.rc-brand1::after,
.rc-icon.rc-human-interaction--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-human-interaction--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-human-interaction--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-human-interaction.interactive.iconography:active::after,
.rc-icon.rc-human-interaction.interactive.iconography:focus::after,
.rc-icon.rc-human-interaction.interactive.iconography:hover::after,
.rc-icon.rc-human-interaction.rc-brand1::after,
.rc-icon.rc-human-interaction.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-human-interaction.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-human-interaction.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-hunt--sm.interactive.iconography:active::after,
.rc-icon.rc-hunt--sm.interactive.iconography:focus::after,
.rc-icon.rc-hunt--sm.interactive.iconography:hover::after,
.rc-icon.rc-hunt--sm.rc-brand1::after,
.rc-icon.rc-hunt--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-hunt--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-hunt--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-hunt.interactive.iconography:active::after,
.rc-icon.rc-hunt.interactive.iconography:focus::after,
.rc-icon.rc-hunt.interactive.iconography:hover::after,
.rc-icon.rc-hunt.rc-brand1::after,
.rc-icon.rc-hunt.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-hunt.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-hunt.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-incompatible--sm.interactive.iconography:active::after,
.rc-icon.rc-incompatible--sm.interactive.iconography:focus::after,
.rc-icon.rc-incompatible--sm.interactive.iconography:hover::after,
.rc-icon.rc-incompatible--sm.rc-brand1::after,
.rc-icon.rc-incompatible--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-incompatible--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-incompatible--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-incompatible.interactive.iconography:active::after,
.rc-icon.rc-incompatible.interactive.iconography:focus::after,
.rc-icon.rc-incompatible.interactive.iconography:hover::after,
.rc-icon.rc-incompatible.rc-brand1::after,
.rc-icon.rc-incompatible.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-incompatible.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-incompatible.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-indoor-outdoor--sm.interactive.iconography:active::after,
.rc-icon.rc-indoor-outdoor--sm.interactive.iconography:focus::after,
.rc-icon.rc-indoor-outdoor--sm.interactive.iconography:hover::after,
.rc-icon.rc-indoor-outdoor--sm.rc-brand1::after,
.rc-icon.rc-indoor-outdoor--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-indoor-outdoor--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-indoor-outdoor--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-indoor-outdoor.interactive.iconography:active::after,
.rc-icon.rc-indoor-outdoor.interactive.iconography:focus::after,
.rc-icon.rc-indoor-outdoor.interactive.iconography:hover::after,
.rc-icon.rc-indoor-outdoor.rc-brand1::after,
.rc-icon.rc-indoor-outdoor.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-indoor-outdoor.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-indoor-outdoor.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-indoors--sm.interactive.iconography:active::after,
.rc-icon.rc-indoors--sm.interactive.iconography:focus::after,
.rc-icon.rc-indoors--sm.interactive.iconography:hover::after,
.rc-icon.rc-indoors--sm.rc-brand1::after,
.rc-icon.rc-indoors--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-indoors--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-indoors--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-indoors.interactive.iconography:active::after,
.rc-icon.rc-indoors.interactive.iconography:focus::after,
.rc-icon.rc-indoors.interactive.iconography:hover::after,
.rc-icon.rc-indoors.rc-brand1::after,
.rc-icon.rc-indoors.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-indoors.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-indoors.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-low-maintenance--sm.interactive.iconography:active::after,
.rc-icon.rc-low-maintenance--sm.interactive.iconography:focus::after,
.rc-icon.rc-low-maintenance--sm.interactive.iconography:hover::after,
.rc-icon.rc-low-maintenance--sm.rc-brand1::after,
.rc-icon.rc-low-maintenance--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-low-maintenance--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-low-maintenance--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-low-maintenance.interactive.iconography:active::after,
.rc-icon.rc-low-maintenance.interactive.iconography:focus::after,
.rc-icon.rc-low-maintenance.interactive.iconography:hover::after,
.rc-icon.rc-low-maintenance.rc-brand1::after,
.rc-icon.rc-low-maintenance.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-low-maintenance.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-low-maintenance.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-medicine--sm.interactive.iconography:active::after,
.rc-icon.rc-medicine--sm.interactive.iconography:focus::after,
.rc-icon.rc-medicine--sm.interactive.iconography:hover::after,
.rc-icon.rc-medicine--sm.rc-brand1::after,
.rc-icon.rc-medicine--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-medicine--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-medicine--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-medicine.interactive.iconography:active::after,
.rc-icon.rc-medicine.interactive.iconography:focus::after,
.rc-icon.rc-medicine.interactive.iconography:hover::after,
.rc-icon.rc-medicine.rc-brand1::after,
.rc-icon.rc-medicine.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-medicine.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-medicine.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-microchip--sm.interactive.iconography:active::after,
.rc-icon.rc-microchip--sm.interactive.iconography:focus::after,
.rc-icon.rc-microchip--sm.interactive.iconography:hover::after,
.rc-icon.rc-microchip--sm.rc-brand1::after,
.rc-icon.rc-microchip--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-microchip--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-microchip--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-microchip.interactive.iconography:active::after,
.rc-icon.rc-microchip.interactive.iconography:focus::after,
.rc-icon.rc-microchip.interactive.iconography:hover::after,
.rc-icon.rc-microchip.rc-brand1::after,
.rc-icon.rc-microchip.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-microchip.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-microchip.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-need-space--sm.interactive.iconography:active::after,
.rc-icon.rc-need-space--sm.interactive.iconography:focus::after,
.rc-icon.rc-need-space--sm.interactive.iconography:hover::after,
.rc-icon.rc-need-space--sm.rc-brand1::after,
.rc-icon.rc-need-space--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-need-space--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-need-space--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-need-space.interactive.iconography:active::after,
.rc-icon.rc-need-space.interactive.iconography:focus::after,
.rc-icon.rc-need-space.interactive.iconography:hover::after,
.rc-icon.rc-need-space.rc-brand1::after,
.rc-icon.rc-need-space.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-need-space.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-need-space.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-no-alone--sm.interactive.iconography:active::after,
.rc-icon.rc-no-alone--sm.interactive.iconography:focus::after,
.rc-icon.rc-no-alone--sm.interactive.iconography:hover::after,
.rc-icon.rc-no-alone--sm.rc-brand1::after,
.rc-icon.rc-no-alone--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-no-alone--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-no-alone--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-no-alone.interactive.iconography:active::after,
.rc-icon.rc-no-alone.interactive.iconography:focus::after,
.rc-icon.rc-no-alone.interactive.iconography:hover::after,
.rc-icon.rc-no-alone.rc-brand1::after,
.rc-icon.rc-no-alone.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-no-alone.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-no-alone.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-no-garden--sm.interactive.iconography:active::after,
.rc-icon.rc-no-garden--sm.interactive.iconography:focus::after,
.rc-icon.rc-no-garden--sm.interactive.iconography:hover::after,
.rc-icon.rc-no-garden--sm.rc-brand1::after,
.rc-icon.rc-no-garden--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-no-garden--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-no-garden--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-no-garden.interactive.iconography:active::after,
.rc-icon.rc-no-garden.interactive.iconography:focus::after,
.rc-icon.rc-no-garden.interactive.iconography:hover::after,
.rc-icon.rc-no-garden.rc-brand1::after,
.rc-icon.rc-no-garden.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-no-garden.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-no-garden.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-outdoor--sm.interactive.iconography:active::after,
.rc-icon.rc-outdoor--sm.interactive.iconography:focus::after,
.rc-icon.rc-outdoor--sm.interactive.iconography:hover::after,
.rc-icon.rc-outdoor--sm.rc-brand1::after,
.rc-icon.rc-outdoor--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-outdoor--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-outdoor--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-outdoor.interactive.iconography:active::after,
.rc-icon.rc-outdoor.interactive.iconography:focus::after,
.rc-icon.rc-outdoor.interactive.iconography:hover::after,
.rc-icon.rc-outdoor.rc-brand1::after,
.rc-icon.rc-outdoor.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-outdoor.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-outdoor.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-pairs--sm.interactive.iconography:active::after,
.rc-icon.rc-pairs--sm.interactive.iconography:focus::after,
.rc-icon.rc-pairs--sm.interactive.iconography:hover::after,
.rc-icon.rc-pairs--sm.rc-brand1::after,
.rc-icon.rc-pairs--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-pairs--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-pairs--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-pairs.interactive.iconography:active::after,
.rc-icon.rc-pairs.interactive.iconography:focus::after,
.rc-icon.rc-pairs.interactive.iconography:hover::after,
.rc-icon.rc-pairs.rc-brand1::after,
.rc-icon.rc-pairs.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-pairs.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-pairs.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-patient--sm.interactive.iconography:active::after,
.rc-icon.rc-patient--sm.interactive.iconography:focus::after,
.rc-icon.rc-patient--sm.interactive.iconography:hover::after,
.rc-icon.rc-patient--sm.rc-brand1::after,
.rc-icon.rc-patient--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-patient--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-patient--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-patient.interactive.iconography:active::after,
.rc-icon.rc-patient.interactive.iconography:focus::after,
.rc-icon.rc-patient.interactive.iconography:hover::after,
.rc-icon.rc-patient.rc-brand1::after,
.rc-icon.rc-patient.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-patient.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-patient.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-quiet-home--sm.interactive.iconography:active::after,
.rc-icon.rc-quiet-home--sm.interactive.iconography:focus::after,
.rc-icon.rc-quiet-home--sm.interactive.iconography:hover::after,
.rc-icon.rc-quiet-home--sm.rc-brand1::after,
.rc-icon.rc-quiet-home--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-quiet-home--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-quiet-home--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-quiet-home.interactive.iconography:active::after,
.rc-icon.rc-quiet-home.interactive.iconography:focus::after,
.rc-icon.rc-quiet-home.interactive.iconography:hover::after,
.rc-icon.rc-quiet-home.rc-brand1::after,
.rc-icon.rc-quiet-home.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-quiet-home.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-quiet-home.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-sleep--sm.interactive.iconography:active::after,
.rc-icon.rc-sleep--sm.interactive.iconography:focus::after,
.rc-icon.rc-sleep--sm.interactive.iconography:hover::after,
.rc-icon.rc-sleep--sm.rc-brand1::after,
.rc-icon.rc-sleep--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-sleep--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-sleep--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-sleep.interactive.iconography:active::after,
.rc-icon.rc-sleep.interactive.iconography:focus::after,
.rc-icon.rc-sleep.interactive.iconography:hover::after,
.rc-icon.rc-sleep.rc-brand1::after,
.rc-icon.rc-sleep.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-sleep.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-sleep.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-sm-home--sm.interactive.iconography:active::after,
.rc-icon.rc-sm-home--sm.interactive.iconography:focus::after,
.rc-icon.rc-sm-home--sm.interactive.iconography:hover::after,
.rc-icon.rc-sm-home--sm.rc-brand1::after,
.rc-icon.rc-sm-home--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-sm-home--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-sm-home--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-sm-home.interactive.iconography:active::after,
.rc-icon.rc-sm-home.interactive.iconography:focus::after,
.rc-icon.rc-sm-home.interactive.iconography:hover::after,
.rc-icon.rc-sm-home.rc-brand1::after,
.rc-icon.rc-sm-home.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-sm-home.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-sm-home.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-sporting--sm.interactive.iconography:active::after,
.rc-icon.rc-sporting--sm.interactive.iconography:focus::after,
.rc-icon.rc-sporting--sm.interactive.iconography:hover::after,
.rc-icon.rc-sporting--sm.rc-brand1::after,
.rc-icon.rc-sporting--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-sporting--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-sporting--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-sporting.interactive.iconography:active::after,
.rc-icon.rc-sporting.interactive.iconography:focus::after,
.rc-icon.rc-sporting.interactive.iconography:hover::after,
.rc-icon.rc-sporting.rc-brand1::after,
.rc-icon.rc-sporting.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-sporting.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-sporting.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-startled--sm.interactive.iconography:active::after,
.rc-icon.rc-startled--sm.interactive.iconography:focus::after,
.rc-icon.rc-startled--sm.interactive.iconography:hover::after,
.rc-icon.rc-startled--sm.rc-brand1::after,
.rc-icon.rc-startled--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-startled--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-startled--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-startled.interactive.iconography:active::after,
.rc-icon.rc-startled.interactive.iconography:focus::after,
.rc-icon.rc-startled.interactive.iconography:hover::after,
.rc-icon.rc-startled.rc-brand1::after,
.rc-icon.rc-startled.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-startled.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-startled.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-training--sm.interactive.iconography:active::after,
.rc-icon.rc-training--sm.interactive.iconography:focus::after,
.rc-icon.rc-training--sm.interactive.iconography:hover::after,
.rc-icon.rc-training--sm.rc-brand1::after,
.rc-icon.rc-training--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-training--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-training--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-training.interactive.iconography:active::after,
.rc-icon.rc-training.interactive.iconography:focus::after,
.rc-icon.rc-training.interactive.iconography:hover::after,
.rc-icon.rc-training.rc-brand1::after,
.rc-icon.rc-training.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-training.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-training.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-vaccination--sm.interactive.iconography:active::after,
.rc-icon.rc-vaccination--sm.interactive.iconography:focus::after,
.rc-icon.rc-vaccination--sm.interactive.iconography:hover::after,
.rc-icon.rc-vaccination--sm.rc-brand1::after,
.rc-icon.rc-vaccination--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-vaccination--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-vaccination--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-vaccination.interactive.iconography:active::after,
.rc-icon.rc-vaccination.interactive.iconography:focus::after,
.rc-icon.rc-vaccination.interactive.iconography:hover::after,
.rc-icon.rc-vaccination.rc-brand1::after,
.rc-icon.rc-vaccination.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-vaccination.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-vaccination.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-versatile--sm.interactive.iconography:active::after,
.rc-icon.rc-versatile--sm.interactive.iconography:focus::after,
.rc-icon.rc-versatile--sm.interactive.iconography:hover::after,
.rc-icon.rc-versatile--sm.rc-brand1::after,
.rc-icon.rc-versatile--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-versatile--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-versatile--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-versatile.interactive.iconography:active::after,
.rc-icon.rc-versatile.interactive.iconography:focus::after,
.rc-icon.rc-versatile.interactive.iconography:hover::after,
.rc-icon.rc-versatile.rc-brand1::after,
.rc-icon.rc-versatile.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-versatile.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-versatile.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-watchdog--sm.interactive.iconography:active::after,
.rc-icon.rc-watchdog--sm.interactive.iconography:focus::after,
.rc-icon.rc-watchdog--sm.interactive.iconography:hover::after,
.rc-icon.rc-watchdog--sm.rc-brand1::after,
.rc-icon.rc-watchdog--sm.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-watchdog--sm.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-watchdog--sm.rc-interactive.rc-iconography:hover::after,
.rc-icon.rc-watchdog.interactive.iconography:active::after,
.rc-icon.rc-watchdog.interactive.iconography:focus::after,
.rc-icon.rc-watchdog.interactive.iconography:hover::after,
.rc-icon.rc-watchdog.rc-brand1::after,
.rc-icon.rc-watchdog.rc-interactive.rc-iconography:active::after,
.rc-icon.rc-watchdog.rc-interactive.rc-iconography:focus::after,
.rc-icon.rc-watchdog.rc-interactive.rc-iconography:hover::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--breed--brand1.svg);
}
.rc-icon.rc-attention--sm.rc-brand3::after,
.rc-icon.rc-attention.rc-brand3::after,
.rc-icon.rc-ball--sm.rc-brand3::after,
.rc-icon.rc-ball.rc-brand3::after,
.rc-icon.rc-behavior--sm.rc-brand3::after,
.rc-icon.rc-behavior.rc-brand3::after,
.rc-icon.rc-bug--sm.rc-brand3::after,
.rc-icon.rc-bug.rc-brand3::after,
.rc-icon.rc-cat-tree--sm.rc-brand3::after,
.rc-icon.rc-cat-tree.rc-brand3::after,
.rc-icon.rc-children--sm.rc-brand3::after,
.rc-icon.rc-children.rc-brand3::after,
.rc-icon.rc-collar--sm.rc-brand3::after,
.rc-icon.rc-collar.rc-brand3::after,
.rc-icon.rc-deafness--sm.rc-brand3::after,
.rc-icon.rc-deafness.rc-brand3::after,
.rc-icon.rc-digestion--sm.rc-brand3::after,
.rc-icon.rc-digestion.rc-brand3::after,
.rc-icon.rc-enjoys-training--sm.rc-brand3::after,
.rc-icon.rc-enjoys-training.rc-brand3::after,
.rc-icon.rc-exercise--sm.rc-brand3::after,
.rc-icon.rc-exercise.rc-brand3::after,
.rc-icon.rc-experience--sm.rc-brand3::after,
.rc-icon.rc-experience.rc-brand3::after,
.rc-icon.rc-family--sm.rc-brand3::after,
.rc-icon.rc-family.rc-brand3::after,
.rc-icon.rc-first-time--sm.rc-brand3::after,
.rc-icon.rc-first-time.rc-brand3::after,
.rc-icon.rc-friendly--sm.rc-brand3::after,
.rc-icon.rc-friendly.rc-brand3::after,
.rc-icon.rc-grooming--sm.rc-brand3::after,
.rc-icon.rc-grooming.rc-brand3::after,
.rc-icon.rc-guard-dog--sm.rc-brand3::after,
.rc-icon.rc-guard-dog.rc-brand3::after,
.rc-icon.rc-heat--sm.rc-brand3::after,
.rc-icon.rc-heat.rc-brand3::after,
.rc-icon.rc-human-interaction--sm.rc-brand3::after,
.rc-icon.rc-human-interaction.rc-brand3::after,
.rc-icon.rc-hunt--sm.rc-brand3::after,
.rc-icon.rc-hunt.rc-brand3::after,
.rc-icon.rc-incompatible--sm.rc-brand3::after,
.rc-icon.rc-incompatible.rc-brand3::after,
.rc-icon.rc-indoor-outdoor--sm.rc-brand3::after,
.rc-icon.rc-indoor-outdoor.rc-brand3::after,
.rc-icon.rc-indoors--sm.rc-brand3::after,
.rc-icon.rc-indoors.rc-brand3::after,
.rc-icon.rc-low-maintenance--sm.rc-brand3::after,
.rc-icon.rc-low-maintenance.rc-brand3::after,
.rc-icon.rc-medicine--sm.rc-brand3::after,
.rc-icon.rc-medicine.rc-brand3::after,
.rc-icon.rc-microchip--sm.rc-brand3::after,
.rc-icon.rc-microchip.rc-brand3::after,
.rc-icon.rc-need-space--sm.rc-brand3::after,
.rc-icon.rc-need-space.rc-brand3::after,
.rc-icon.rc-no-alone--sm.rc-brand3::after,
.rc-icon.rc-no-alone.rc-brand3::after,
.rc-icon.rc-no-garden--sm.rc-brand3::after,
.rc-icon.rc-no-garden.rc-brand3::after,
.rc-icon.rc-outdoor--sm.rc-brand3::after,
.rc-icon.rc-outdoor.rc-brand3::after,
.rc-icon.rc-pairs--sm.rc-brand3::after,
.rc-icon.rc-pairs.rc-brand3::after,
.rc-icon.rc-patient--sm.rc-brand3::after,
.rc-icon.rc-patient.rc-brand3::after,
.rc-icon.rc-quiet-home--sm.rc-brand3::after,
.rc-icon.rc-quiet-home.rc-brand3::after,
.rc-icon.rc-sleep--sm.rc-brand3::after,
.rc-icon.rc-sleep.rc-brand3::after,
.rc-icon.rc-sm-home--sm.rc-brand3::after,
.rc-icon.rc-sm-home.rc-brand3::after,
.rc-icon.rc-sporting--sm.rc-brand3::after,
.rc-icon.rc-sporting.rc-brand3::after,
.rc-icon.rc-startled--sm.rc-brand3::after,
.rc-icon.rc-startled.rc-brand3::after,
.rc-icon.rc-training--sm.rc-brand3::after,
.rc-icon.rc-training.rc-brand3::after,
.rc-icon.rc-vaccination--sm.rc-brand3::after,
.rc-icon.rc-vaccination.rc-brand3::after,
.rc-icon.rc-versatile--sm.rc-brand3::after,
.rc-icon.rc-versatile.rc-brand3::after,
.rc-icon.rc-watchdog--sm.rc-brand3::after,
.rc-icon.rc-watchdog.rc-brand3::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--breed--brand3.svg);
}
.icon[class*='--xs'].rc-iconography:after,
.rc-icon.iconography--xs::after,
.rc-icon.rc-iconography--xs::after,
.rc-icon[class*='--xs'].iconography:after,
.rc-icon[class*='--xs'].rc-iconography:after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--iconography--xs.svg);
  width: 24px;
}
.rc-icon.iconography::after,
.rc-icon.rc-iconography::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--iconography.svg);
}
.btn--icon-label.rc-icon[aria-selected='true']:not(.btn--inverse)::after,
.btn--icon-label.rc-icon[aria-selected='true']:not(.rc-btn--inverse)::after,
.rc-btn--icon-label.rc-icon[aria-selected='true']:not(.btn--inverse)::after,
.rc-btn--icon-label.rc-icon[aria-selected='true']:not(.rc-btn--inverse)::after,
.rc-btn--icon-tab.rc-icon[aria-selected='true']:not(.btn--inverse)::after,
.rc-btn--icon-tab.rc-icon[aria-selected='true']:not(.rc-btn--inverse)::after,
.rc-icon.brand1--xs::after,
.rc-icon.rc-brand1--xs::after,
.rc-icon[class*='--xs'].brand1:after,
.rc-icon[class*='--xs'].rc-brand1:after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--brand1--xs.svg);
  width: 24px;
}
.rc-icon.brand1::after,
.rc-icon.rc-brand1::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--brand1.svg);
}
.rc-icon.brand3--xs::after,
.rc-icon.rc-brand3--xs::after,
.rc-icon[class*='--xs'].brand3:after,
.rc-icon[class*='--xs'].rc-brand3:after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--brand3--xs.svg);
  width: 24px;
}
.btn--icon-label.rc-icon:not(.btn--inverse):active::after,
.btn--icon-label.rc-icon:not(.btn--inverse):focus::after,
.btn--icon-label.rc-icon:not(.btn--inverse):hover::after,
.btn--icon-label.rc-icon:not(.rc-btn--inverse):active::after,
.btn--icon-label.rc-icon:not(.rc-btn--inverse):focus::after,
.btn--icon-label.rc-icon:not(.rc-btn--inverse):hover::after,
.rc-address--xs.rc-interactive.rc-iconography:active::after,
.rc-address--xs.rc-interactive.rc-iconography:focus::after,
.rc-address--xs.rc-interactive.rc-iconography:hover::after,
.rc-arrow--xs.rc-interactive.rc-iconography:active::after,
.rc-arrow--xs.rc-interactive.rc-iconography:focus::after,
.rc-arrow--xs.rc-interactive.rc-iconography:hover::after,
.rc-attachment--xs.rc-interactive.rc-iconography:active::after,
.rc-attachment--xs.rc-interactive.rc-iconography:focus::after,
.rc-attachment--xs.rc-interactive.rc-iconography:hover::after,
.rc-back--xs.rc-interactive.rc-iconography:active::after,
.rc-back--xs.rc-interactive.rc-iconography:focus::after,
.rc-back--xs.rc-interactive.rc-iconography:hover::after,
.rc-bag--xs.rc-interactive.rc-iconography:active::after,
.rc-bag--xs.rc-interactive.rc-iconography:focus::after,
.rc-bag--xs.rc-interactive.rc-iconography:hover::after,
.rc-basket--xs.rc-interactive.rc-iconography:active::after,
.rc-basket--xs.rc-interactive.rc-iconography:focus::after,
.rc-basket--xs.rc-interactive.rc-iconography:hover::after,
.rc-bookmark--xs.rc-interactive.rc-iconography:active::after,
.rc-bookmark--xs.rc-interactive.rc-iconography:focus::after,
.rc-bookmark--xs.rc-interactive.rc-iconography:hover::after,
.rc-btn--icon-label.rc-icon:not(.btn--inverse):active::after,
.rc-btn--icon-label.rc-icon:not(.btn--inverse):focus::after,
.rc-btn--icon-label.rc-icon:not(.btn--inverse):hover::after,
.rc-btn--icon-label.rc-icon:not(.rc-btn--inverse):active::after,
.rc-btn--icon-label.rc-icon:not(.rc-btn--inverse):focus::after,
.rc-btn--icon-label.rc-icon:not(.rc-btn--inverse):hover::after,
.rc-btn--icon-tab.rc-icon:not(.btn--inverse):active::after,
.rc-btn--icon-tab.rc-icon:not(.btn--inverse):focus::after,
.rc-btn--icon-tab.rc-icon:not(.btn--inverse):hover::after,
.rc-btn--icon-tab.rc-icon:not(.rc-btn--inverse):active::after,
.rc-btn--icon-tab.rc-icon:not(.rc-btn--inverse):focus::after,
.rc-btn--icon-tab.rc-icon:not(.rc-btn--inverse):hover::after,
.rc-calendar--xs.rc-interactive.rc-iconography:active::after,
.rc-calendar--xs.rc-interactive.rc-iconography:focus::after,
.rc-calendar--xs.rc-interactive.rc-iconography:hover::after,
.rc-cart--xs.rc-interactive.rc-iconography:active::after,
.rc-cart--xs.rc-interactive.rc-iconography:focus::after,
.rc-cart--xs.rc-interactive.rc-iconography:hover::after,
.rc-clock--xs.rc-interactive.rc-iconography:active::after,
.rc-clock--xs.rc-interactive.rc-iconography:focus::after,
.rc-clock--xs.rc-interactive.rc-iconography:hover::after,
.rc-collapse--xs.rc-interactive.rc-iconography:active::after,
.rc-collapse--xs.rc-interactive.rc-iconography:focus::after,
.rc-collapse--xs.rc-interactive.rc-iconography:hover::after,
.rc-comment--xs.rc-interactive.rc-iconography:active::after,
.rc-comment--xs.rc-interactive.rc-iconography:focus::after,
.rc-comment--xs.rc-interactive.rc-iconography:hover::after,
.rc-computer--xs.rc-interactive.rc-iconography:active::after,
.rc-computer--xs.rc-interactive.rc-iconography:focus::after,
.rc-computer--xs.rc-interactive.rc-iconography:hover::after,
.rc-contact--xs.rc-interactive.rc-iconography:active::after,
.rc-contact--xs.rc-interactive.rc-iconography:focus::after,
.rc-contact--xs.rc-interactive.rc-iconography:hover::after,
.rc-down--xs.rc-interactive.rc-iconography:active::after,
.rc-down--xs.rc-interactive.rc-iconography:focus::after,
.rc-down--xs.rc-interactive.rc-iconography:hover::after,
.rc-download--xs.rc-interactive.rc-iconography:active::after,
.rc-download--xs.rc-interactive.rc-iconography:focus::after,
.rc-download--xs.rc-interactive.rc-iconography:hover::after,
.rc-edit--xs.rc-interactive.rc-iconography:active::after,
.rc-edit--xs.rc-interactive.rc-iconography:focus::after,
.rc-edit--xs.rc-interactive.rc-iconography:hover::after,
.rc-expand--xs.rc-interactive.rc-iconography:active::after,
.rc-expand--xs.rc-interactive.rc-iconography:focus::after,
.rc-expand--xs.rc-interactive.rc-iconography:hover::after,
.rc-external--xs.rc-interactive.rc-iconography:active::after,
.rc-external--xs.rc-interactive.rc-iconography:focus::after,
.rc-external--xs.rc-interactive.rc-iconography:hover::after,
.rc-fast-forward--xs.rc-interactive.rc-iconography:active::after,
.rc-fast-forward--xs.rc-interactive.rc-iconography:focus::after,
.rc-fast-forward--xs.rc-interactive.rc-iconography:hover::after,
.rc-female--xs.rc-interactive.rc-iconography:active::after,
.rc-female--xs.rc-interactive.rc-iconography:focus::after,
.rc-female--xs.rc-interactive.rc-iconography:hover::after,
.rc-filter--xs.rc-interactive.rc-iconography:active::after,
.rc-filter--xs.rc-interactive.rc-iconography:focus::after,
.rc-filter--xs.rc-interactive.rc-iconography:hover::after,
.rc-food--xs.rc-interactive.rc-iconography:active::after,
.rc-food--xs.rc-interactive.rc-iconography:focus::after,
.rc-food--xs.rc-interactive.rc-iconography:hover::after,
.rc-food2--xs.rc-interactive.rc-iconography:active::after,
.rc-food2--xs.rc-interactive.rc-iconography:focus::after,
.rc-food2--xs.rc-interactive.rc-iconography:hover::after,
.rc-forward--xs.rc-interactive.rc-iconography:active::after,
.rc-forward--xs.rc-interactive.rc-iconography:focus::after,
.rc-forward--xs.rc-interactive.rc-iconography:hover::after,
.rc-grid-view--xs.rc-interactive.rc-iconography:active::after,
.rc-grid-view--xs.rc-interactive.rc-iconography:focus::after,
.rc-grid-view--xs.rc-interactive.rc-iconography:hover::after,
.rc-groomer--xs.rc-interactive.rc-iconography:active::after,
.rc-groomer--xs.rc-interactive.rc-iconography:focus::after,
.rc-groomer--xs.rc-interactive.rc-iconography:hover::after,
.rc-health--xs.rc-interactive.rc-iconography:active::after,
.rc-health--xs.rc-interactive.rc-iconography:focus::after,
.rc-health--xs.rc-interactive.rc-iconography:hover::after,
.rc-help--xs.rc-interactive.rc-iconography:active::after,
.rc-help--xs.rc-interactive.rc-iconography:focus::after,
.rc-help--xs.rc-interactive.rc-iconography:hover::after,
.rc-hide--xs.rc-interactive.rc-iconography:active::after,
.rc-hide--xs.rc-interactive.rc-iconography:focus::after,
.rc-hide--xs.rc-interactive.rc-iconography:hover::after,
.rc-image--xs.rc-interactive.rc-iconography:active::after,
.rc-image--xs.rc-interactive.rc-iconography:focus::after,
.rc-image--xs.rc-interactive.rc-iconography:hover::after,
.rc-info--xs.rc-interactive.rc-iconography:active::after,
.rc-info--xs.rc-interactive.rc-iconography:focus::after,
.rc-info--xs.rc-interactive.rc-iconography:hover::after,
.rc-interactive.rc-iconography--xs:active::after,
.rc-interactive.rc-iconography--xs:focus::after,
.rc-interactive.rc-iconography--xs:hover::after,
.rc-knowledge--xs.rc-interactive.rc-iconography:active::after,
.rc-knowledge--xs.rc-interactive.rc-iconography:focus::after,
.rc-knowledge--xs.rc-interactive.rc-iconography:hover::after,
.rc-like--xs.rc-interactive.rc-iconography:active::after,
.rc-like--xs.rc-interactive.rc-iconography:focus::after,
.rc-like--xs.rc-interactive.rc-iconography:hover::after,
.rc-list-view--xs.rc-interactive.rc-iconography:active::after,
.rc-list-view--xs.rc-interactive.rc-iconography:focus::after,
.rc-list-view--xs.rc-interactive.rc-iconography:hover::after,
.rc-loading--xs.rc-interactive.rc-iconography:active::after,
.rc-loading--xs.rc-interactive.rc-iconography:focus::after,
.rc-loading--xs.rc-interactive.rc-iconography:hover::after,
.rc-location--xs.rc-interactive.rc-iconography:active::after,
.rc-location--xs.rc-interactive.rc-iconography:focus::after,
.rc-location--xs.rc-interactive.rc-iconography:hover::after,
.rc-location2--xs.rc-interactive.rc-iconography:active::after,
.rc-location2--xs.rc-interactive.rc-iconography:focus::after,
.rc-location2--xs.rc-interactive.rc-iconography:hover::after,
.rc-lock--xs.rc-interactive.rc-iconography:active::after,
.rc-lock--xs.rc-interactive.rc-iconography:focus::after,
.rc-lock--xs.rc-interactive.rc-iconography:hover::after,
.rc-male--xs.rc-interactive.rc-iconography:active::after,
.rc-male--xs.rc-interactive.rc-iconography:focus::after,
.rc-male--xs.rc-interactive.rc-iconography:hover::after,
.rc-minus--xs.rc-interactive.rc-iconography:active::after,
.rc-minus--xs.rc-interactive.rc-iconography:focus::after,
.rc-minus--xs.rc-interactive.rc-iconography:hover::after,
.rc-mouse--xs.rc-interactive.rc-iconography:active::after,
.rc-mouse--xs.rc-interactive.rc-iconography:focus::after,
.rc-mouse--xs.rc-interactive.rc-iconography:hover::after,
.rc-new-window--xs.rc-interactive.rc-iconography:active::after,
.rc-new-window--xs.rc-interactive.rc-iconography:focus::after,
.rc-new-window--xs.rc-interactive.rc-iconography:hover::after,
.rc-news--xs.rc-interactive.rc-iconography:active::after,
.rc-news--xs.rc-interactive.rc-iconography:focus::after,
.rc-news--xs.rc-interactive.rc-iconography:hover::after,
.rc-nutritional--xs.rc-interactive.rc-iconography:active::after,
.rc-nutritional--xs.rc-interactive.rc-iconography:focus::after,
.rc-nutritional--xs.rc-interactive.rc-iconography:hover::after,
.rc-pause--xs.rc-interactive.rc-iconography:active::after,
.rc-pause--xs.rc-interactive.rc-iconography:focus::after,
.rc-pause--xs.rc-interactive.rc-iconography:hover::after,
.rc-pdf--xs.rc-interactive.rc-iconography:active::after,
.rc-pdf--xs.rc-interactive.rc-iconography:focus::after,
.rc-pdf--xs.rc-interactive.rc-iconography:hover::after,
.rc-pharmacy--xs.rc-interactive.rc-iconography:active::after,
.rc-pharmacy--xs.rc-interactive.rc-iconography:focus::after,
.rc-pharmacy--xs.rc-interactive.rc-iconography:hover::after,
.rc-play--xs.rc-interactive.rc-iconography:active::after,
.rc-play--xs.rc-interactive.rc-iconography:focus::after,
.rc-play--xs.rc-interactive.rc-iconography:hover::after,
.rc-plus--xs.rc-interactive.rc-iconography:active::after,
.rc-plus--xs.rc-interactive.rc-iconography:focus::after,
.rc-plus--xs.rc-interactive.rc-iconography:hover::after,
.rc-print--xs.rc-interactive.rc-iconography:active::after,
.rc-print--xs.rc-interactive.rc-iconography:focus::after,
.rc-print--xs.rc-interactive.rc-iconography:hover::after,
.rc-quality--xs.rc-interactive.rc-iconography:active::after,
.rc-quality--xs.rc-interactive.rc-iconography:focus::after,
.rc-quality--xs.rc-interactive.rc-iconography:hover::after,
.rc-rate--xs.rc-interactive.rc-iconography:active::after,
.rc-rate--xs.rc-interactive.rc-iconography:focus::after,
.rc-rate--xs.rc-interactive.rc-iconography:hover::after,
.rc-rate-fill--xs.rc-interactive.rc-iconography:active::after,
.rc-rate-fill--xs.rc-interactive.rc-iconography:focus::after,
.rc-rate-fill--xs.rc-interactive.rc-iconography:hover::after,
.rc-refresh--xs.rc-interactive.rc-iconography:active::after,
.rc-refresh--xs.rc-interactive.rc-iconography:focus::after,
.rc-refresh--xs.rc-interactive.rc-iconography:hover::after,
.rc-rewind--xs.rc-interactive.rc-iconography:active::after,
.rc-rewind--xs.rc-interactive.rc-iconography:focus::after,
.rc-rewind--xs.rc-interactive.rc-iconography:hover::after,
.rc-right--xs.rc-interactive.rc-iconography:active::after,
.rc-right--xs.rc-interactive.rc-iconography:focus::after,
.rc-right--xs.rc-interactive.rc-iconography:hover::after,
.rc-rss--xs.rc-interactive.rc-iconography:active::after,
.rc-rss--xs.rc-interactive.rc-iconography:focus::after,
.rc-rss--xs.rc-interactive.rc-iconography:hover::after,
.rc-settings--xs.rc-interactive.rc-iconography:active::after,
.rc-settings--xs.rc-interactive.rc-iconography:focus::after,
.rc-settings--xs.rc-interactive.rc-iconography:hover::after,
.rc-share--xs.rc-interactive.rc-iconography:active::after,
.rc-share--xs.rc-interactive.rc-iconography:focus::after,
.rc-share--xs.rc-interactive.rc-iconography:hover::after,
.rc-shelter--xs.rc-interactive.rc-iconography:active::after,
.rc-shelter--xs.rc-interactive.rc-iconography:focus::after,
.rc-shelter--xs.rc-interactive.rc-iconography:hover::after,
.rc-shop--xs.rc-interactive.rc-iconography:active::after,
.rc-shop--xs.rc-interactive.rc-iconography:focus::after,
.rc-shop--xs.rc-interactive.rc-iconography:hover::after,
.rc-show--xs.rc-interactive.rc-iconography:active::after,
.rc-show--xs.rc-interactive.rc-iconography:focus::after,
.rc-show--xs.rc-interactive.rc-iconography:hover::after,
.rc-skip--xs.rc-interactive.rc-iconography:active::after,
.rc-skip--xs.rc-interactive.rc-iconography:focus::after,
.rc-skip--xs.rc-interactive.rc-iconography:hover::after,
.rc-stop--xs.rc-interactive.rc-iconography:active::after,
.rc-stop--xs.rc-interactive.rc-iconography:focus::after,
.rc-stop--xs.rc-interactive.rc-iconography:hover::after,
.rc-sustainable--xs.rc-interactive.rc-iconography:active::after,
.rc-sustainable--xs.rc-interactive.rc-iconography:focus::after,
.rc-sustainable--xs.rc-interactive.rc-iconography:hover::after,
.rc-tablet--xs.rc-interactive.rc-iconography:active::after,
.rc-tablet--xs.rc-interactive.rc-iconography:focus::after,
.rc-tablet--xs.rc-interactive.rc-iconography:hover::after,
.rc-text-decrease--xs.rc-interactive.rc-iconography:active::after,
.rc-text-decrease--xs.rc-interactive.rc-iconography:focus::after,
.rc-text-decrease--xs.rc-interactive.rc-iconography:hover::after,
.rc-text-increase--xs.rc-interactive.rc-iconography:active::after,
.rc-text-increase--xs.rc-interactive.rc-iconography:focus::after,
.rc-text-increase--xs.rc-interactive.rc-iconography:hover::after,
.rc-tick--xs.rc-interactive.rc-iconography:active::after,
.rc-tick--xs.rc-interactive.rc-iconography:focus::after,
.rc-tick--xs.rc-interactive.rc-iconography:hover::after,
.rc-unlock--xs.rc-interactive.rc-iconography:active::after,
.rc-unlock--xs.rc-interactive.rc-iconography:focus::after,
.rc-unlock--xs.rc-interactive.rc-iconography:hover::after,
.rc-user-add--xs.rc-interactive.rc-iconography:active::after,
.rc-user-add--xs.rc-interactive.rc-iconography:focus::after,
.rc-user-add--xs.rc-interactive.rc-iconography:hover::after,
.rc-vet--xs.rc-interactive.rc-iconography:active::after,
.rc-vet--xs.rc-interactive.rc-iconography:focus::after,
.rc-vet--xs.rc-interactive.rc-iconography:hover::after,
.rc-video--xs.rc-interactive.rc-iconography:active::after,
.rc-video--xs.rc-interactive.rc-iconography:focus::after,
.rc-video--xs.rc-interactive.rc-iconography:hover::after,
.rc-volume-off--xs.rc-interactive.rc-iconography:active::after,
.rc-volume-off--xs.rc-interactive.rc-iconography:focus::after,
.rc-volume-off--xs.rc-interactive.rc-iconography:hover::after,
.rc-volume-on--xs.rc-interactive.rc-iconography:active::after,
.rc-volume-on--xs.rc-interactive.rc-iconography:focus::after,
.rc-volume-on--xs.rc-interactive.rc-iconography:hover::after,
.rc-wechat--xs.rc-interactive.rc-iconography:active::after,
.rc-wechat--xs.rc-interactive.rc-iconography:focus::after,
.rc-wechat--xs.rc-interactive.rc-iconography:hover::after,
.rc-weight--xs.rc-interactive.rc-iconography:active::after,
.rc-weight--xs.rc-interactive.rc-iconography:focus::after,
.rc-weight--xs.rc-interactive.rc-iconography:hover::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--brand1--xs.svg);
}
.rc-icon.brand3::after,
.rc-icon.rc-brand3::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--brand3.svg);
}
.rc-interactive.rc-iconography:active::after,
.rc-interactive.rc-iconography:focus::after,
.rc-interactive.rc-iconography:hover::after {
  background-image: url(https://dl.royalcanin.com/8-8-1/royal-canin.sprite--brand1.svg);
}
.rc-address .rc-icon {
  padding-left: 1.2rem;
}
.rc-address .rc-icon::after {
  left: -1rem;
  position: absolute;
  top: 0;
}
.alpha.rc-header-with-icon .rc-icon,
.beta.rc-header-with-icon .rc-icon,
.delta.rc-header-with-icon .rc-icon,
.espilon.rc-header-with-icon .rc-icon,
.gamma.rc-header-with-icon .rc-icon,
.intro.rc-header-with-icon .rc-icon,
.large-body.rc-header-with-icon .rc-icon,
.large-intro.rc-header-with-icon .rc-icon,
.meta.rc-header-with-icon .rc-icon,
.numeric.rc-header-with-icon .rc-icon,
.rc-alpha.rc-header-with-icon .rc-icon,
.rc-beta.rc-header-with-icon .rc-icon,
.rc-delta.rc-header-with-icon .rc-icon,
.rc-espilon.rc-header-with-icon .rc-icon,
.rc-gamma.rc-header-with-icon .rc-icon,
.rc-header-with-icon .rc-icon,
.rc-header-with-icon.rc-header-with-icon--alpha .rc-icon,
.rc-header-with-icon.rc-header-with-icon--beta .rc-icon,
.rc-header-with-icon.rc-header-with-icon--delta .rc-icon,
.rc-header-with-icon.rc-header-with-icon--espilon .rc-icon,
.rc-header-with-icon.rc-header-with-icon--gamma .rc-icon,
.rc-header-with-icon.rc-header-with-icon--intro .rc-icon,
.rc-header-with-icon.rc-header-with-icon--large-body .rc-icon,
.rc-header-with-icon.rc-header-with-icon--large-intro .rc-icon,
.rc-header-with-icon.rc-header-with-icon--meta .rc-icon,
.rc-header-with-icon.rc-header-with-icon--numeric .rc-icon,
.rc-header-with-icon.rc-header-with-icon--zeta .rc-icon,
.rc-intro.rc-header-with-icon .rc-icon,
.rc-large-body.rc-header-with-icon .rc-icon,
.rc-large-intro.rc-header-with-icon .rc-icon,
.rc-meta.rc-header-with-icon .rc-icon,
.rc-numeric.rc-header-with-icon .rc-icon,
.rc-replace-header-styles h1.rc-header-with-icon .rc-icon,
.rc-replace-header-styles h2.rc-header-with-icon .rc-icon,
.rc-replace-header-styles h3.rc-header-with-icon .rc-icon,
.rc-replace-header-styles h4.rc-header-with-icon .rc-icon,
.rc-replace-header-styles h5.rc-header-with-icon .rc-icon,
.rc-replace-header-styles h6.rc-header-with-icon .rc-icon,
.rc-wysiwyg h1.rc-header-with-icon .rc-icon,
.rc-wysiwyg h2.rc-header-with-icon .rc-icon,
.rc-wysiwyg h3.rc-header-with-icon .rc-icon,
.rc-wysiwyg h4.rc-header-with-icon .rc-icon,
.rc-wysiwyg h5.rc-header-with-icon .rc-icon,
.rc-wysiwyg h6.rc-header-with-icon .rc-icon,
.rc-zeta.rc-header-with-icon .rc-icon,
.zeta.rc-header-with-icon .rc-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.alpha.rc-header-with-icon .rc-icon::after,
.rc-alpha.rc-header-with-icon .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--alpha .rc-icon::after,
.rc-replace-header-styles h1.rc-header-with-icon .rc-icon::after,
.rc-wysiwyg h1.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.938);
  transform: scale(0.938);
}
.beta.rc-header-with-icon .rc-icon::after,
.rc-beta.rc-header-with-icon .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--beta .rc-icon::after,
.rc-replace-header-styles h2.rc-header-with-icon .rc-icon::after,
.rc-wysiwyg h2.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.812);
  transform: scale(0.812);
}
.gamma.rc-header-with-icon .rc-icon::after,
.rc-gamma.rc-header-with-icon .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--gamma .rc-icon::after,
.rc-replace-header-styles h3.rc-header-with-icon .rc-icon::after,
.rc-wysiwyg h3.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.688);
  transform: scale(0.688);
}
.delta.rc-header-with-icon .rc-icon::after,
.large-intro.rc-header-with-icon .rc-icon::after,
.rc-delta.rc-header-with-icon .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--delta .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--large-intro .rc-icon::after,
.rc-large-intro.rc-header-with-icon .rc-icon::after,
.rc-replace-header-styles h4.rc-header-with-icon .rc-icon::after,
.rc-wysiwyg h4.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.562);
  transform: scale(0.562);
}
.espilon.rc-header-with-icon .rc-icon::after,
.intro.rc-header-with-icon .rc-icon::after,
.large-body.rc-header-with-icon .rc-icon::after,
.rc-espilon.rc-header-with-icon .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--espilon .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--intro .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--large-body .rc-icon::after,
.rc-intro.rc-header-with-icon .rc-icon::after,
.rc-large-body.rc-header-with-icon .rc-icon::after,
.rc-replace-header-styles h5.rc-header-with-icon .rc-icon::after,
.rc-wysiwyg h5.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
.rc-header-with-icon.rc-header-with-icon--zeta .rc-icon::after,
.rc-replace-header-styles h6.rc-header-with-icon .rc-icon::after,
.rc-wysiwyg h6.rc-header-with-icon .rc-icon::after,
.rc-zeta.rc-header-with-icon .rc-icon::after,
.zeta.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.438);
  transform: scale(0.438);
}
.meta.rc-header-with-icon .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--meta .rc-icon::after,
.rc-meta.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.375);
  transform: scale(0.375);
}
.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.438);
  transform: scale(0.438);
}
.rc-inline-icon .rc-icon {
  display: inline-block;
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0.438);
  transform: scale(0.438);
  width: 14px;
}
.numeric.rc-header-with-icon .rc-icon::after,
.rc-header-with-icon.rc-header-with-icon--numeric .rc-icon::after,
.rc-numeric.rc-header-with-icon .rc-icon::after {
  margin-right: 0.3em;
  -webkit-transform: scale(0.625);
  transform: scale(0.625);
}
.rc-loading .rc-btn.rc-icon::after,
.rc-loading .rc-btn.rc-icon::before {
  display: none;
}
.btn--icon-label:not(.btn--inverse).rc-icon[aria-selected='true'],
.btn--icon-label:not(.rc-btn--inverse).rc-icon[aria-selected='true'],
.rc-btn--icon-label:not(.btn--inverse).rc-icon[aria-selected='true'],
.rc-btn--icon-label:not(.rc-btn--inverse).rc-icon[aria-selected='true'],
.rc-btn--icon-tab:not(.btn--inverse).rc-icon[aria-selected='true'],
.rc-btn--icon-tab:not(.rc-btn--inverse).rc-icon[aria-selected='true'] {
  color: $primary;
}
.list__item.rc-icon::after,
.rc-list__item.rc-icon::after,
.rc-wysiwyg ol > li.rc-icon::after,
.rc-wysiwyg ul > li.rc-icon::after {
  left: -2em;
  margin-top: -4px;
  position: absolute;
  top: 0;
}
nav[data-toggle-group=''] .list__link:not(.rc-icon),
nav[data-toggle-group=''] .rc-list__link:not(.rc-icon),
nav[data-toggle-group='desktop'] .list__link:not(.rc-icon),
nav[data-toggle-group='desktop'] .rc-list__link:not(.rc-icon) {
  padding: 1rem 1.5rem;
}
@media (max-width: 769px) {
  nav[data-toggle-group='mobile'] .list__link:not(.rc-icon),
  nav[data-toggle-group='mobile'] .rc-list__link:not(.rc-icon) {
    padding: 1rem 1.5rem;
  }
}
.list__link.rc-icon .rc-menubar .list__link::after,
.list__link.rc-icon .rc-menubar .rc-list__link::after,
.rc-list__link.rc-icon .rc-menubar .list__link::after,
.rc-list__link.rc-icon .rc-menubar .rc-list__link::after {
  left: 0.5rem;
  top: 10%;
}
.list__link.rc-icon,
.rc-list__link.rc-icon {
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  padding-top: 1rem;
}
@media (min-width: 769px) {
  .list__link.rc-icon,
  .rc-list__link.rc-icon {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}
.list__link.rc-icon::after,
.rc-list__link.rc-icon::after {
  left: 0.5rem;
  margin-top: -1rem;
  position: absolute;
  top: 50%;
}
.rc-menubar .list__link.rc-icon::after,
.rc-menubar .rc-list__link.rc-icon::after {
  left: 1.5rem;
  margin-top: 0;
}
.rc-icon-list {
  padding-left: 3rem;
}
.rc-icon-list .rc-list__item {
  margin-bottom: 16px;
}
.rc-icon-list .rc-list__item::after,
.rc-icon-list .rc-list__item[class*='--sm']::after {
  left: -2.5em;
}
.rc-icon-list .rc-list__item[class*='--xs']::after {
  left: -2em;
}
.rc-icon-list--sm,
.rc-icon-list--xs {
  margin-bottom: 8px;
  padding-left: 2.5rem;
}
.rc-interactive-image--toggle-content h3 span.rc-hanging-indent.rc-icon {
  left: -1.9rem;
  top: 0.05rem;
}
@media all and (min-width: 768px) {
  .rc-interactive-image--toggle-content h3 span.rc-hanging-indent.rc-icon {
    left: -2rem;
    top: 0.25rem;
  }
}
.input.rc-icon::after,
.rc-input.rc-icon::after {
  position: absolute !important;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* stylelint-enable */
