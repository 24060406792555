/**
 * Buttons
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

@mixin rc-button-base {
  cursor: pointer;

  display: inline-block;
  line-height: 36px;
  margin: 0;
  min-width: 64px;
  outline: 0;
  overflow: visible;
  padding: 0 16px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  text-align: center;

  text-decoration: none;
  transition: background-color 300ms ease-in;
  user-select: none;
  vertical-align: baseline;
}

.rc-button-wrapper {
  position: relative;
  top: 50%;

  transform: translateY(-50%);
}

.rc-button {
  @include rc-button-base();
  background-color: transparent;

  border: none;
  font-family: 'DINProMedium', Roboto, Avenir, Helvetica, Arial, sans-serif;

  font-size: 16px;

  &__title {
    color: $grey-dark;
  }
  &__title--link {
    border-bottom: 1px solid $grey-lighter;
    transition: border-bottom-color 300ms ease-in-out;

    &:hover {
      border-bottom-color: $grey-dark;
    }
  }

  &__title-red {
    color: $primary;
  }
  &__title--link-red {
    border-bottom: 1px solid $primary;
    transition: border-bottom-color 300ms ease-in-out;

    &:hover {
      border-bottom-color: $error;
    }
  }

  &__separator {
    margin-right: 15px;
  }

  &--filled-regular,
  &--filled-small,
  &--outline-regular,
  &--outline-small {
    background-color: $primary;
    border-radius: 30px;

    .rc-button__title {
      color: $white;
    }
  }

  &--filled-regular,
  &--outline-regular {
    height: 48px;
    line-height: 48px;
    padding: 0 34px;
  }

  &--filled-small,
  &--outline-small {
    height: 40px;
    padding: 0 24px;
    // line-height: 40px;

    .rc-button__title {
      font-size: 14px;
    }
  }

  &--outline-regular,
  &--outline-small {
    background-color: transparent;
    border: 2px solid $primary;

    .rc-button__title {
      color: $primary;
    }
  }

  &--outline-regular {
    line-height: 46px;
  }
  // &--outline-small { line-height: 38px; }

  /* Hover */

  &--filled-regular:hover,
  &--filled-small:hover {
    background-color: $primary-dark;
  }

  &--outline-regular:hover,
  &--outline-small:hover {
    border-color: $primary-dark;
  }

  /* Disabled */

  &--filled-regular:disabled,
  &--filled-small:disabled {
    background-color: $grey-lighter;
    cursor: default;
  }

  &--outline-regular:disabled,
  &--outline-small:disabled {
    border-color: $grey-lighter;
    cursor: default;

    .rc-button__title {
      color: $grey-lighter;
    }
  }

  /* Icon with text */

  &__icon {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }

  [class^='rc-icon'] {
    margin-right: 10px;
  }
}

.rc-button-group {
  position: relative;

  .rc-button__separator {
    margin-right: 10px;
  }
}

.rc-fab-button {
  @include rc-button-base();
  background-color: $primary;

  border-radius: 50%;
  height: 48px;

  line-height: 48px;
  min-width: 0;

  padding: 0;
  width: 48px;

  &:hover {
    background-color: color.adjust($primary, $lightness: -4%);
  }
}

.rc-link,
.rc-link-footer {
  color: $grey-dark;
  cursor: pointer;

  font-family: $din-medium;
  transition: all 300ms ease-in;
}

.rc-link {
  border-bottom: 1px solid #d7d7d7;

  // &:hover {
  //   border-bottom-color: $primary;
  //   color: $primary;
  // }

  &--disabled {
    cursor: default;
    opacity: 0.3;
  }

  &--error {
    border-color: $warning;
    color: $warning;
    float: right;
    margin-right: 15px;
  }
}

.rc-link-footer {
  border-bottom: 1px solid $grey-lighter;

  &:hover {
    border-color: $grey-dark;
  }
}
