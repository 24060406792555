@use 'sass:string';
@use 'sass:map';
@use 'sass:list';
@use 'sass:color';
/**
 * Main
 *
 * This file import all our stylesheet.
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Angular CDK
   ========================================================================== */

@import '~@angular/cdk/overlay-prebuilt.css';

/* Utils
   ========================================================================== */
@import 'utils/site-variables';
@import 'utils/functions';
@import 'utils/mixins';
@import 'utils/site-settings';
@import 'utils/icons';

/* Base
   ========================================================================== */
@import 'base/reset';
@import 'base/base';

/* Module
   ========================================================================== */
@import 'module/button';
@import 'module/input';
@import 'module/checkbox';

// This stupid Mars footer have a default CSS like this: `#mars-footer-panel { margin: 0 !important; }`.
// So, we need to add some selector (like `body`) to be sure our rule is used...
body #mars-footer-panel {
  margin-bottom: 20px !important;
}
