@import 'theme';
/**
 * Site variables
 *
 * This allow us to reuse those variable inside any views / components
 * It's also avoid to duplicate code.
 *
 * Copyright © 2021 Royal Canin. All rights reserved.
 */

$base-font-size: 16px;

$din-regular: 'DINProRegular', Roboto, Avenir, Helvetica, Arial, sans-serif;
$din-medium: 'DINProMedium', Roboto, Avenir, Helvetica, Arial, sans-serif;
$din-bold: 'DINProBold', Roboto, Avenir, Helvetica, Arial, sans-serif;
$helvetica: 'Helvetica', Arial, sans-serif;

$primary: $theme-primary;

// To be used for hovers
$primary-dark: $theme-primary-dark;
$black: #000;
$white: #fff;

// To be used for app background and separators
$app-background: #f6f6f6;

// To be used darker text color
$grey-darker: #333;

// To be used for hyperlink
$grey-dark: #444;

// To be used for text color and darker borders
$grey: #666;

// To be used for lighter text color and icon colors
$grey-light: #767676;

// To be used for box shadow
$grey-shadow: #b3b3b3;

// To be used for borders
$grey-lighter: #d7d7d7;

$success: #008900;
$warning: #ee8b00;
$error: #c03344;
$info: #5ca9ad;

// Spacing
$size-1: 48px;
$size-2: 40px;
$size-3: 32px;
$size-4: 24px;
$size-5: 20px;
$size-6: 16px;
$size-7: 12px;
$size-8: 8px;
$size-9: 5px;

// RC Ui mapping
$rc-ui-required-asterisk-color: var(--rc-field-label-required-asterisk-color);
$rc-field-color-error: var(--rc-field-color-error);
