.rc-checkbox {
  height: auto;
  position: relative;

  input {
    cursor: pointer;

    display: inline-block;
    height: 24px;
    opacity: 0;
    position: relative;

    vertical-align: middle;
    width: 24px;
    z-index: 1;
  }

  &__label {
    margin-left: 10px;
    vertical-align: middle;
  }

  &__checkmark {
    background-color: $white;

    border: 1px solid $grey-lighter;
    border-radius: 3px;
    cursor: pointer;

    height: 24px;
    left: 0;
    position: absolute;
    top: 0;

    vertical-align: middle;
    width: 24px;

    &:after {
      border: solid $primary;
      border-width: 0 2px 2px 0;
      content: '';

      display: none;
      height: 14px;
      left: 7px;
      position: absolute;
      top: 2px;

      transform: rotate(45deg);
      width: 8px;
    }
  }

  input:checked ~ &__checkmark:after {
    display: block;
  }

  input:disabled {
    cursor: not-allowed;
  }
  input:disabled ~ &__checkmark:after {
    border: solid $grey-lighter;
    border-width: 0 2px 2px 0;
  }

  &--condition {
    margin-top: 20px;
  }
  &--condition &__label {
    display: inline-block;

    vertical-align: top;
    width: 235px;
  }
}

/**
 * Devices between (375px and up, 736px and down)
 */
@media only screen and (min-width: 375px) and (max-width: 736px) and (orientation: landscape) {
  .rc-checkbox--condition .rc-checkbox__label {
    width: calc(100% - 50px);
  }
}
