/**
 * Functions
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Functions
   ========================================================================== */

/**
 * Replace string to load font face
 */

@function str-replace($string, $search, $replace: '') {
  $index: string.index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}
